<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="项目名称">
              <a-input placeholder="请输入项目名称" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="负责人名称">
              <a-input placeholder="请输入负责人名称" v-model="queryParam.chargeName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="margin: 8px 0px;">
      <a-button @click="handleAdd" :disabled="permission" type="primary" icon="plus">新增</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay" v-if="!permission">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete" />删除</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
        <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
        >项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x: true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
      >
        <template slot="accepterName" slot-scope="text, record">
          <select-multi-user :text="text" @change="onCellChange(record.id, 'accepterName', $event)" />
        </template>
        <template slot="chargeName" slot-scope="text, record">
          <select-multi-user :text="text" @change="onCellChange(record.id, 'chargeName', $event)" />
        </template>
        <template slot="planStartTime" slot-scope="text, record">
          <date-table :text="text" @change="onCellChange(record.id, 'planStartTime', $event)" />
        </template>
        <template slot="planEndTime" slot-scope="text, record">
          <date-table :text="text" @change="onCellChange(record.id, 'planEndTime', $event)" />
        </template>
        <template slot="startTime" slot-scope="text, record">
          <date-table :text="text" @change="onCellChange(record.id, 'startTime', $event)" />
        </template>
        <template slot="endTime" slot-scope="text, record">
          <date-table :text="text" @change="onCellChange(record.id, 'endTime', $event)" />
        </template>
        <template slot="budgetAmount" slot-scope="text, record">
          <input-table :text="text" valueType="number" @change="onCellChange(record.id, 'budgetAmount', $event)" />
        </template>
        <template slot="remark" slot-scope="text, record">
          <TextareaTable :text="text" @change="onCellChange(record.id, 'remark', $event)" />
        </template>
        <template slot="name" slot-scope="text, record">
          <input-table :text="text" @change="onCellChange(record.id, 'name', $event)" />
        </template>
        <span slot="status" slot-scope="text">
          <a-tag :color="text | statusColor">{{ status[text] || '未启动' }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="openGantt(record)">甘特图</a>
          <a-divider type="vertical" />
          <a @click="openHrTask(record)">任务列表</a>
          <a-divider type="vertical" />
          <a-dropdown v-if="!permission">
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                  <a @click="handleActiviti(record)">{{ record.actStatus == 0 ? '启动流程' : '流程信息'}}</a>
              </a-menu-item>
              <a-menu-item v-if="!record.status || record.status == 1">
                <a @click="handleStart(record)">启动项目</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleAddTask(record)">添加任务</a>
              </a-menu-item>
              <a-menu-item v-if="!permission">
                <a @click="handleEnd(record)">结束项目</a>
              </a-menu-item>
              <!-- <a-menu-item v-if="!permission">
                <a @click="handleEdit(record)">编辑</a>
              </a-menu-item> -->
              <a-menu-item v-if="!permission">
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>

    <!-- 表单区域 -->
    <!-- <HrProjectModal ref="modalForm" @ok="modalFormOk"></HrProjectModal> -->
    <!-- <HrTaskModal ref="hrTaskModal" @ok="modalFormOk"></HrTaskModal> -->
    <!-- <GanttModal ref="ganttModal"></GanttModal> -->
    <HrProjectStartModal ref="HrProjectStartModal" @ok="modalFormOk"></HrProjectStartModal>
  </a-card>
</template>

<script>
import { baseUrl } from "@/api/api.js"
import '@/assets/less/TableExpand.less'
import {
  getProcessByKey,
  qdApply,
  getTodoList,
  getProcessByActi,
  getActStartProcess
} from '@/api/activitiApi';
// import HrProjectModal from '@/views/hrProjectManage/hrProject/modules/HrProjectModal'
// import HrTaskModal from '@/views/hrProjectManage/hrTask/modules/HrTaskModal'
// import GanttModal from '@/views/hrProjectManage/hrProject/modules/GanttModal'
import HrProjectStartModal from '@/views/hrProjectManage/hrProject/modules/HrProjectStartModal'
import InputTable from "@/views/hrProjectManage/components/InputTable"
import TextareaTable from "@/views/hrProjectManage/components/TextareaTable"
import DateTable from "@/views/hrProjectManage/components/DateTable"
import SelectMultiUser from "@/views/hrProjectManage/components/SelectMultiUser"
import { whiteList } from "@/views/hrProjectManage/permission.js"
import { mapGetters } from "vuex"
import { GetAtion, HttpAction } from "@/utils/http"
import { JeecgListMixin } from '@/views/mixins/JeecgListMixin'
import { status } from "@/views/hrProjectManage/permission.js"

export default {
  name: 'HrProject',
  mixins: [JeecgListMixin],
  components: {
    // HrProjectModal,
    // HrTaskModal,
    // GanttModal,
    HrProjectStartModal,
    InputTable,
    DateTable,
    SelectMultiUser,
    TextareaTable,
  },
  data() {
    return {
      description: 'hr_project-分页列表查询',
      status,
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          },
        },
        {
          title: '项目名称',
          align: 'center',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '流程状态',
          align: 'center',
          dataIndex: 'actStatus',
          customRender: function (t, r, index) {
            if (t) {
              if (t == 1) return '进行中';
              if (t == 2) return '已结束';
            }
            return '未启动'
          },
        },
        {
          title: '负责人',
          align: 'center',
          dataIndex: 'chargeName',
          width: 150,
          scopedSlots: { customRender: 'chargeName' },
        },
        {
          title: '项目状态',
          align: 'center',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '预算金额',
          align: 'center',
          dataIndex: 'budgetAmount',
          scopedSlots: { customRender: 'budgetAmount' },
        },
        {
          title: '计划开始时间',
          align: 'center',
          dataIndex: 'planStartTime',
          scopedSlots: { customRender: 'planStartTime' },
        },
        {
          title: '计划结束时间',
          align: 'center',
          dataIndex: 'planEndTime',
          scopedSlots: { customRender: 'planEndTime' },
        },
        {
          title: '实际开始时间',
          align: 'center',
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
        },
        {
          title: '实际结束时间',
          align: 'center',
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
        },
        {
          title: '备注',
          align: 'center',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' },
        },
        {
          title: '验收人',
          align: 'center',
          dataIndex: 'accepterName',
          scopedSlots: { customRender: 'accepterName' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
          width: 180
        },
      ],
      url: {
        list: baseUrl + '/hr-server/hrProject/list',
        delete: baseUrl + '/hr-server/hrProject/delete',
        deleteBatch: baseUrl + '/hr-server/hrProject/deleteBatch',
        add: baseUrl + "/hr-server/hrProject/add",
        edit: baseUrl + "/hr-server/hrProject/edit",
        exportXlsUrl: baseUrl + 'loan/loanContract/exportXls',
        importExcelUrl: baseUrl + 'loan/loanContract/importExcel',
        queryRolesByUserName: baseUrl + '/sfsj-server/sys/role/queryRolesByUserName'
      },
      permission: true
    }
  },
  filters: {
    statusColor(value) {
      const statusColor = {
        0: 'pink',
        1: 'pink',
        2: '#108ee9',
        3: '#87d068',
        4: '#f50',
        5: 'blue',
        6: 'purple'
      }
      return statusColor[value]
    },
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
    ...mapGetters(['userInfo'])
  },
  created() {
    GetAtion(this.url.queryRolesByUserName, { userName: this.userInfo.username }).then(res=> {
      if (res.success) {
        let array = res.result.map(item => item.roleCode)
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          if (whiteList.indexOf(element) > -1) {
            this.permission = false
            break;
          }
        }
      } else {
        this.$message.warning('查询权限失败')
      }
      
    })
  },
  methods: {
    
    onCellChange(id, dataIndex, value) {
      if (this.permission) {
        this.$message.warning('无权修改，请联系管理员！')
        return;
      }
      const dataSource = [...this.dataSource];
      const target = dataSource.find(item => item.id === id);
      if (target) {
        target[dataIndex] = value;
        target['hrProjectMemberList'] = [];
        this.loading = true;
        getActStartProcess('hr_project').then(result=>{
          HttpAction(this.url.edit, {
            domain: target,
            processName: result.result.name,
            procDefId: result.result.id
          },'put').then(res => {
            if (res.success) {
              this.dataSource = dataSource;
            } else {
              this.$message.warning(res.message)
            }
          }).finally(() => {
            this.loading = false;
          })
        }).catch(err=> {
          this.loading = false;
        })
      }
    },
    handleAdd() {
      this.loading = true;
      getActStartProcess('hr_project').then(result=>{
        if (result.success) {
          const obj = {
            domain: {
              name: '点击填写项目名称',
              hrProjectMemberList: []
            },
            processName: result.result.name,
            procDefId: result.result.id
          }
          HttpAction(this.url.add, obj,'post').then(res=> {
            if (res.success) {
              this.modalFormOk()
            } else {
              this.loading = false;
              this.$message.warning(res.message)
            }
          })
        }
      })
      
    },
    // 添加任务跳转至流程页面
    handleAddTask(record) {
      if (record.chargeName != this.userInfo.username && this.permission) {
        this.$message.warning('暂无权限添加任务！')
        return;
      }
      if (!record.status || record.status == 1) {
        this.$message.warning('项目未启动不可以添加任务')
        return
      }
      getActStartProcess('hr_task').then(res=>{
        if (res.success) {
          this.addApply(res.result, {
            projectId: record.id,
            pid: '',
            activitiType: 1
          })
        }
      })
      // this.$refs.hrTaskModal.title = '新增'
      // this.$refs.hrTaskModal.add(record.id)
    },
    //发起申请
    addApply(record, query) {
      this.$router.push({
        path:'/activitiPage',
        query:{
          type:'apply',
          title:`发起了[${record.name}]`,
          processInstanceId: '', // 流程记录&流程高亮的参数(申请过去不需要)
          definitionId:record.id, // 获取表单formKey&modelId参数&启动&表单保存时参数
          // category:record.category, //启动&表单保存参数
          processName:record.name, //启动&表单保存参数
          ...query // 请求携带的路由参数，用于表单默认值
        }
      });
    },
    openGantt(record) {
      // this.$refs.ganttModal.edit(record)
      let routeUrl = this.$router.resolve({
        path: "/gantt/hrProjectManage",
        query: { id: record.id }
      });
      window.open(routeUrl.href, '_blank');
    },
    openHrTask(record) {
      this.$router.push({
        path: '/hrProjectManage/hrTask',
        query: {
          projectId: record.id
        }
      })
    },
    // handleAdd: function () {
    //   this.$refs.modalForm.add();
    //   this.$refs.modalForm.title = "新增";
    //   this.$refs.modalForm.disableSubmit = false;
    // },
    handleStart(record) {
      if (record.actStatus != 2) {
        this.$message.warning('流程未结束，不可启动项目')
        return
      }
      this.$refs.HrProjectStartModal.edit(record, 'start');
      this.$refs.HrProjectStartModal.title = record.name;
      this.$refs.HrProjectStartModal.disableSubmit = false;
    },
    handleEnd(record) {
      if (!record.status || record.status == 1) {
        this.$message.warning('项目未启动不可以结束')
        return
      }
      this.$refs.HrProjectStartModal.edit(record, 'end');
      this.$refs.HrProjectStartModal.title = record.name;
      this.$refs.HrProjectStartModal.disableSubmit = false;
    },
    handleActiviti(record) {
      this.loading = true
      if (record.actStatus != 0) {
        getTodoList({
          pageNum: 1,
          pageSize: 10,
          // processName: "",
          type: "todo",
          businessKey: record.id
        }).then(res=> {
          if (res.success) {
            if (res.result.list.length) {
              let data = res.result.list[0]
              this.lookHandler(data)
            } else {
              this.handleDetail(record)
            }
          } else {
            this.$message.warning(res.message)
          }
        }).catch(err=>{
          this.loading = false
        })
      } else {
        this.againApply(record)
      }
    },
    // 查看并处理
    lookHandler(record) {
      this.loading = false
      this.$router.push({
        path:'/newActiviti/task/historyInfo',
        query:{ 
          type:'deal',
          title:`发起了[${record.taskName}]`,//标题
          processInstanceId: record.instanceId, // 流程记录&流程高亮的参数
          definitionId:record.definitionId, // 流程定义id
          taskId:record.taskId, //节点id
          businessKey:record.businessKey, // 表单json回显id
          processName: record.processName
          // taskId: record.taskId
        }
      });
    },
    // 详情
    handleDetail(record) {
      if (!record.activitiId) {
        this.loading = false
        this.$message.warning('没有流程id');
        return
      }
      this.getProc(record.activitiId).then(res => {
        this.loading = false
        this.$router.push({
          path: '/newActiviti/task/historyInfo',
          query:{ 
            type: 'detail',
            status: record.actStatus,
            title: `申请的[${res.processName}]`,//标题
            processInstanceId: res.instanceId, // 流程记录&流程高亮的参数
            definitionId: res.definitionId, // 表单formKey&modelId参数&启动&表单保存时参数 key(以前)
            businessKey: res.businessKey,
          }
        });
      }).catch(err=>{
        this.loading = false
      })
    },
    // 获取流程数据---编辑/查看/启动
    getProc(activitiId) {
      return getProcessByActi(activitiId).then(res => {
        if(res.success) {
          return res.result;
        }else {
          this.$message.warning(res.message);
        }
      })
    },
    // 启动 / 重新提交
    againApply(record) {
      if(!record.activitiId) {
        this.$message.warning('请先启动流程');
        return
      }
      let variables = {}
      this.getProc(record.activitiId).then(res => {
        variables = res.variables ? JSON.parse(res.variables) : {type:''};
        qdApply({
          username:this.userInfo.username,
          definitionId:res.definitionId,
          category:res.category,
          id:res.businessKey, // businessKey
          variables:variables
        }).then(resp => {
          if(resp.success) {
            this.$message.success('任务启动成功')
            this.loadData();
          }else {
            this.$message.error('任务启动失败')
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('任务启动失败')
        })
      })
    },
  },
}
</script>
<style scoped>
</style>