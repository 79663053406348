<template>
    <a-modal
        :width="1200"
        :visible="visible"
        :title="title"
        switchFullscreen
        wrapClassName="j-user-select-modal"
        @ok="handleSubmit"
        @cancel="close"
        style="top:50px"
        cancelText="关闭">
        <a-row class="slot_modal" :gutter="20">
            <a-col :xs="24" :sm="24" :md="10" :lg="10" :xl="8">
                <div class="left">
                    <!-- 部门 -->
                    <a-directory-tree
                        selectable
                        :selectedKeys="selectedDepIds"
                        :checkStrictly="true"
                        :dropdownStyle="{maxHeight:'200px',overflow:'auto'}"
                        :treeData="departTree"
                        :expandAction="false"
                        :expandedKeys.sync="expandedKeys"
                        @select="onDepSelect"
                    />
                </div>
            </a-col>
            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="16">
                <div class="right">
                    <!-- 去去去 -->
                    <div class="top_search">
                        用户账号：
                        <a-input-search
                            :style="{width:'150px',marginBottom:'15px'}"
                            placeholder="请输入账号"
                            v-model="userParam"
                            @search="onSearch"
                        ></a-input-search>
                        <a-button @click="searchReset(1)" style="margin-left: 20px" icon="redo">重置</a-button>
                    </div>
                    <a-table
                        ref="table"
                        :scroll="scrollTrigger"
                        size="middle"
                        rowKey="id"
                        :columns="columns"
                        :dataSource="dataSource"
                        :pagination="ipagination"
                        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type: getType}"
                        :loading="loading"
                        @change="handleTableChange">
                    </a-table>
                </div>
            </a-col>
        </a-row>
    </a-modal>
</template>

<script>
    import {filterObj,preIcon} from '@/utils/util'
    import { queryDepartTreeList, queryUserByDepId, getUserLists} from '@/api/api'
export default {
    // name:'弹出框内容 slot插槽'
    props:{
        title: {
            type:String,
            default: '选择人员'
        },
        //用户列表多选/单选
        multi:{
            type:Boolean,
            default:true
        },
        //搜索选着的用户
        selUse:{
            type:Array,
            default:()=>[]
        }
    },
    data() {
        return {
            visible:false,
            loading: false,
            scrollTrigger:{},
            /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
            queryParam: {},
            //分页
            ipagination: {
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                    return range[0] + '-' + range[1] + ' 共' + total + '条'
                },
                // showQuickJumper: true,
                // showSizeChanger: true,
                total: 0
            },
            isorter: {
                column: 'createTime',
                order: 'desc'
            },
            selectedRowKeys:[],
            selectionRows:[],
            // selectUserIds:'',
            dataSource:[],
            columns:[
                {
                    title: '用户账号',
                    align: 'center',
                    dataIndex: 'username'
                },
                {
                    title: '用户姓名',
                    align: 'center',
                    dataIndex: 'realname'
                },
                {
                    title: '性别',
                    align: 'center',
                    dataIndex: 'sex',
                    customRender: function (text) {
                    if (text === 1) {
                        return '男'
                    } else if (text === 2) {
                        return '女'
                    } else {
                        return text
                    }
                    }
                },
                {
                    title: '手机',
                    align: 'center',
                    dataIndex: 'phone'
                },
                {
                    title: '部门',
                    align: 'center',
                    dataIndex: 'orgCodeTxt'
                }
            ],

            //部门
            selectedDepIds: [],
            expandedKeys: [],
            departTree:[],
            //用户表
            userParam:''
        }
    },
    computed: {
        // 计算属性的 getter
        getType: function () {
            return this.multi == true ? 'checkbox' : 'radio';
        }
    },
    created() {
        this.resetScreenSize();
        this.queryDepartTree();
        this.loadData();
    },
    methods: {
        //用户选择
        onSelectChange(selectedRowKeys, selectionRows) {
            this.selectedRowKeys = selectedRowKeys;
            console.log('table用户选择',selectedRowKeys, selectionRows);
            this.selectionRows = selectionRows;
        },
        // 分页、排序、筛选变化时触发
        handleTableChange(pagination, filters, sorter) {
            //TODO 筛选
            if (Object.keys(sorter).length > 0) {
                this.isorter.column = sorter.field;
                this.isorter.order = 'ascend' === sorter.order ? 'asc' : 'desc';
            }
            this.ipagination = pagination;
            this.loadData();
        },
        // 触发屏幕自适应
        resetScreenSize() {
            let screenWidth = document.body.clientWidth;
            if (screenWidth < 500) {
                this.scrollTrigger = {x: 800};
            } else {
                this.scrollTrigger = {};
            }
        },
        // 查询用户列表
        async loadData(arg) {
            if (arg === 1) {
                this.ipagination.current = 1;
            }
            this.loading = true
            let params = this.getQueryParams()//查询条件
            await getUserLists(params).then((res) => {
                if (res.success) {
                    this.dataSource = res.result.records
                    this.ipagination.total = res.result.total
                }
            }).finally(() => {
                this.loading = false
            })
            // }
        },
        // 点击树节点,筛选出对应的用户
        onDepSelect(selectedDepIds) {
            if (selectedDepIds[0] != null) {
                this.initQueryUserByDepId(selectedDepIds); // 调用方法根据选选择的id查询用户信息
                if (this.selectedDepIds[0] !== selectedDepIds[0]) {
                    this.selectedDepIds = [selectedDepIds[0]];
                }
            }
        },
        // 根据选择的id来查询用户信息
        initQueryUserByDepId(selectedDepIds) {
            this.loading = true
            return queryUserByDepId({id: selectedDepIds.toString()}).then((res) => {
                if (res.success) {
                    this.dataSource = res.result;
                    this.ipagination.total = res.result.length;
                }
            }).finally(() => {
                this.loading = false
            })
        },
        //查询部门
        queryDepartTree() {
            queryDepartTreeList().then((res) => {
                if (res.success) {
                    this.departTree = res.result;
                    // 默认展开父节点
                    this.expandedKeys = this.departTree.map(item => item.id)
                }
            })
        },
        getQueryParams() {
            let param = Object.assign({}, this.queryParam, this.isorter);
            param.field = this.getQueryField();
            param.pageNo = this.ipagination.current;
            param.pageSize = this.ipagination.pageSize;
            return filterObj(param);
        },
        getQueryField() {
            let str = 'id,';
            for (let a = 0; a < this.columns.length; a++) {
                str += ',' + this.columns[a].dataIndex;
            }
            return str;
        },
        //搜索
        onSearch() {
            this.queryParam['username'] = preIcon(this.userParam);
            this.loadData(1);
        },
        //重置
        searchReset(num) {
            let that = this;
            if (num !== 0) {
                that.queryParam = {};
                that.loadData(1);
            }
            that.selectedRowKeys = [];
            that.selectionRows = [];
            // that.selectUserIds = '';
            that.selectedDepIds = [];
            that.userParam = ''
        },
        show() {
            this.visible = true;
            this.selectedRowKeys = [];
            //如果搜索选择后,选择人员时要显示
            console.log('搜索选择的数据====',this.selUse);
            if(this.selUse.length) {
                this.selUse.forEach(el => {
                    let id = el.key
                    this.selectedRowKeys.push(id);
                })
            }
        },
        //弹窗关闭
        close() {
            this.visible = false;
            //置空操作
        },
        //弹窗确认
        handleSubmit() {
            this.close();
            //操作
            // console.log('确认',this.selectionRows,this.selectedRowKeys);
            let selectionRows = [];
            selectionRows = this.selectionRows.map(user => ({
                key: user.username,
                label: user.username
            }));
            this.$emit('selUser',selectionRows);
        },
    }
}
</script>

<style lang="less" scoped>
.slot_modal {
    background-color: #e8e8e8;
    padding: 10px 0;
    .left,.right {background-color: #fff;}
    .left {
        max-height: 700px;
        overflow-y: scroll;
    }
    .top_search {padding: 20px 10px 10px;}
}
</style>