<template>
  <div class="bc">
    <a-carousel :after-change="onChange" :autoplay="true" :autoplaySpeed='104000'>
    <div>
      <cityEacharts  :fynum='fynum'></cityEacharts>
    </div>
    <div>
      <provincialCapitalEacharts :fynum='fynum'></provincialCapitalEacharts>
      </div>
    <div><provinceEacharts :fynum='fynum'></provinceEacharts></div>
  </a-carousel>
  <div class="box"></div>
  </div>
</template>

<script>
import cityEacharts from './components/cityEacharts'
import provincialCapitalEacharts from './components/provincialCapitalEacharts'
import provinceEacharts from './components/provinceEacharts'



export default {
  name:'ChinaEacharts',
  data() {
    return {
     fynum:0,
  }},
  components: {
    cityEacharts,
    provincialCapitalEacharts,
    provinceEacharts
  },
  mounted() {
     

  },
  methods: {
    onChange(current){
       this.fynum=current
    }

  }
};
</script>
<style lang="less"  scoped>
.ant-carousel{
  text-align: center;
  height: 100vh;
  // line-height: 100vh;
  // background: #364d79;
   background-color: rgb(5, 14, 71);
  
  // overflow: scroll;
  z-index: 9999999999999999;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.bc{
  position: relative;
  overflow: hidden;


}
.box{
  position: absolute;
  left: 390px;
  top: 0;
  width: 500px;
  height: 300px;
  z-index: 99999999999999999;

  //  background-color: red;
}

</style>

