<template>
  <div  :class="[pcOrh5==1?'bc':'bc2']">
    <div ref="map" class="chinaMap" >
    </div>
    <div class="listbox">
      <a-table class="ant-table-striped"
       size="small" 
       :dataSource="dataSource" 
       :columns="columns" 
       @change="handleTableChange" 
       :pagination="{
         defaultPageSize:26,
         current:num
       }"
       />
    </div>
  </div>
</template>

<script>
import { 
  Toast,
} from "vant";
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import {  
  BarChart,
  LineChart
   } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {   
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent
   } from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
// import { LabelLayout } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import { getmapDataList } from "@/api/api"

var myChart;
// 注册必须的组件
echarts.use([
  TitleComponent,
   TooltipComponent,
    GridComponent, 
    BarChart,LineChart, 
    CanvasRenderer,
    VisualMapComponent
]);
import "../chinajs/china";
import { GetAtion } from "@/utils/http";

export default {
  name:'provincialCapitalEacharts',
  props:['fynum'],

  data() {
    return {
      timer:null,
      pcOrh5:1, //1为PC端,2为移动端
      subtitle:'',
      title:'',
      num:1,
      dataSource:null,
         columns: [
          {
            title: '地区',
            dataIndex: 'name',
            key: 'name',
            className:'tabledq',
            align:"center",
            
          },
          {
            title: '分子公司数量',
            dataIndex: 'value',
            className:'tablesj',
            align:"center",

          }
         
        ],
      option: {
       title: {
          text: "全国市场数据统计",
          subtext: "各省分布",
          textStyle:{
           color:'#fff',
           fontSize:16
          },
          subtextStyle:{
           color:'#fff',
           fontSize:12
          }
        },
           tooltip: {
                  show: false
                },
                toolbox: {

        　　show: true,

        　　feature: {

        　　　　saveAsImage: {

        　　　　show:true,

        　　　　excludeComponents :['toolbox'],
               title:'下载',
        　　　　pixelRatio: 2,
               backgroundColor:'rgb(5, 18, 77)'

        　　　　}

        　　}

        },
        series: [
          {
            name: "场区数量", //名称
            type: "map", //对应的图标类型
            map: "china", //对应的地图名称
            zoom: "1.2", //缩放的倍数当前为放大1.1倍
            roam: false, //是否在中心点显示文字
            scaleLimit:{
              max:1.2,
              min:1.2
            },
            label: {
              show: true, //是否显示地图上方的省的名字
              color: "#fff",
              fontSize: 12, //显示字体的大小
              fontWeight: 500,
            },
            data: this.dataSource, //地图内填充的数据  格式为{{name:'',value:''}}
            itemStyle: {
              areaColor: "#33486E", //控制区域的背景颜色
              borderColor: "#eee", //控制区域周边边框的颜色
            },
            select: {
              //区域被选中时的一些样式
              //文字样式
              label: {
                color: "#fff",
              },
              //区域样式
              itemStyle: {
                areaColor: "#2776E4", //控制区域的背景颜色
              },
            },
            //高亮显示的区域
            emphasis: {
              label: {
                fontSize: 16,
                color: "#fff",
              },
              itemStyle: {
                areaColor: "#2776E4", //控制区域的背景颜色
              },
            },
          },
        ],
        //鼠标悬浮展示数据
        tooltip: {
          trigger: "item",
        },
        //视觉映射
        visualMap: {
          type:"piecewise",
          show:true,
          //piecewise: true, // 开启后pieces和地图在同一块div内
          pieces: [
            { min: 100 }, // 不指定 max，表示 max 为无限大（Infinity）。
            { min: 50, max: 100 },
            { min: 20, max: 50 },
            { min: 5, max: 20 },
            // { min: 1, max: 5 },
            // { min: 0, max: 1, label: "10 到 200（自定义label）" },
           //  { min: 10, max: 200, label: "10 到 200（自定义label）" },
           // { value: 123, label: "123（自定义特殊颜色）", color: "grey" }, // 表示 value 等于 123 的情况。
            { max: 5 }, // 不指定 min，表示 min 为无限大（-Infinity）。
          ],
          //选择文字块时地图区域的颜色的颜色
          inRange: {
            symbol:"rect",//配置文字前面方块的形状，目前为长方形 circle为圆形
            color: ["#d3adf7","#9254de", "#391085"],
            symbolSize: [30, 100],
          },
          //设置范围区间的文字颜色
          textStyle:{
            color:"#fff"
          }
        },

      },
      myChart: undefined,
    };
  },
  components: {},
  watch:{
      num:{
      handler(){
        // getmapDataList()

       if(this.timer){
          clearTimeout(this.timer)
        }
         this.timer= setTimeout(()=>{
         this.num++
       if(Math.ceil(this.dataSource.length/26)<this.num){
         this.num=1
       }
      },8000)
      },
      immediate: true
      // let times=setTimeout()
   
    },
    fynum:{
  handler(){ 
   if(this.fynum==2){
      
        this.num=1
   }
      },
      immediate: true
    }
  },
   beforeDestroy(){
   if(this.timer){
          clearTimeout(this.timer)
        }
  },
  mounted() {
    this.getmapDataList()
    this.num=1
       //页面首次加载获取屏幕宽度
   if(document.body.clientWidth<1200){
       this.pcOrh5=2
       this.option.title.subtextStyle.fontSize=10
       this.option.title.textStyle.fontSize=18
       this.option.series[0].label.fontSize=10

   }else{
       this.pcOrh5=1
       this.option.title.subtextStyle.fontSize=16
       this.option.title.textStyle.fontSize=24
       this.option.series[0].label.fontSize=16
   }
    // 动态监听屏幕尺寸变化从而修改font-size
    window.onresize = () => {
     console.log(document.documentElement.getBoundingClientRect().width,'屏幕尺寸变化了')
     if(document.documentElement.getBoundingClientRect().width<1200){
       this.pcOrh5=2
       this.option.title.subtextStyle=24

     }else{
       this.pcOrh5=1
       this.option.title.subtextStyle=16
       
     }
    }
  },
  methods: {
handleTableChange(e){
this.num=e.current
},
     async getmapDataList(){
       try {
     let data= await getmapDataList({type:1,startTime:this.$route.query.startTime||null,endTime:this.$route.query.endTime||null})
     console.log(data,'123321')
     this.subtitle=data.result.subtitle
     this.title=data.result.title
     this.dataSource=data.result.data
     
       var myChart =echarts.init(this.$refs.map);
       myChart.setOption(this.option);
        this.myChart = myChart;
       //从后端请求数据
          this.option.series[0].data = this.dataSource;
          this.option.title.subtext= this.subtitle
          this.option.title.text= this.title

      //进行地图赋值
          this.myChart.setOption(this.option);
        //  this.option.visualMap.pieces[0].min=this.dataSource[0].value
       } catch (error) {
         
       }
     }
   
  },
}
</script>
<style lang="less" scoped>
.bc {
  padding: 10px;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex; 
  align-items: center;
  justify-content: space-between;
  // background-color:  #fafafa;
  background: url("../../../assets/eachartsbg.png") no-repeat center center fixed;
  background-size:cover;
//  /* 此处加上-ms前缀，让IE识别 */ 
//   -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../../assets/eachartsbg.png',sizingMethod='scale')";
//   filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../../assets/eachartsbg.png',sizingMethod='scale');
//   /* \9 是 IE的CSS hack,IE9以下识别，不包括IE 9 */
//   background: none\9;
  .chinaMap{
  padding: 10px;
  width: 100vw;
  height: 100vh;
 
  }
   .listbox{
    z-index: 999999999;
    margin-top: 10px;
       margin-right: 50px;
        width: 500px;

    height: 100%;
   /deep/ .ant-table-body{
     margin: 0;
     border:0;

   }
    /deep/ .ant-table-small{
    border: 1px solid #78808b;
    // border-bottom:1px solid #78808b ;
    border-right:0 ;
  }
  /deep/ .ant-table-striped td{
     color: #fff;
     font-weight: 600;
     padding: 1px !important;
     height: .3125rem !important;
     box-sizing: border-box;
     font-size: 13px;
     border-right:1px solid #78808b;
     border-bottom:1px solid #78808b;
  }
   
 /deep/ .ant-table-thead tr{
     .ant-table-align-center{
       height: .4688rem !important;
       box-sizing: border-box;
       background-color: #003a8c !important;
       border-bottom:1px solid #78808b;
       border-right:1px solid #78808b;
       color: #fff;
     }
  }
  }


}
.bc2{
   padding: .1563rem;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  display: flex; 
  align-items: center;
  // justify-content: center;
  flex-flow: column;
  // background-color:  #fafafa;
  background: url("../../../assets/eachartsbg.png") no-repeat center center fixed;
  background-size:cover;
  background-color: rgb(5, 14, 71);


  .chinaMap{
  // padding: .1563rem;
  width: 100%;
  height: 100%;
 
  }
   .listbox{
    z-index: 999999999;
    margin-top: .4688rem;
    margin-right: .7813rem;
    width: 60%;
    height: 100%;
   /deep/ .ant-table-body{
     margin: 0;
     border:0;

   }
  /deep/ .ant-table-small{
    border: 1px solid #78808b;
    // border-bottom:1px solid #78808b ;
    border-right:0 ;
  }
  /deep/ .ant-table-striped td{
     color: #fff;
     font-weight: 600;
     padding: 1px !important;
     height: .3125rem !important;
     box-sizing: border-box;
     font-size: .2031rem;
     border-right:1px solid #78808b;
     border-bottom:1px solid #78808b;
  }
   
 /deep/ .ant-table-thead tr{
     .ant-table-align-center{
       height: .4688rem !important;
       box-sizing: border-box;
       background-color: #003a8c !important;
       border-bottom:1px solid #78808b;
       border-right:1px solid #78808b;
       color: #fff;
     }
  }
  }

}

</style>

