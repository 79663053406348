<template>
  <j-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    :okButtonProps="{ class:{'jee-hidden': disableSubmit} }"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭">
    <a-spin :spinning="confirmLoading">
      <a-form-model ref="form" :model="model" :rules="validatorRules">
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="note" label="任务记录">
          <a-input placeholder="请输入任务记录" v-model="model.note" />
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="hour" label="使用工时">
          <a-input-number style="width: 50%" placeholder="请输入使用工时" v-model="model.hour" :min="0" />
        </a-form-model-item>
        
      </a-form-model>
    </a-spin>
  </j-modal>
</template>

<script>
  import { baseUrl } from "@/api/api.js"
  import { GetAtion, HttpAction } from "@/utils/http"

  export default {
    name: "HrProjectModal",
    components: {},
    data () {
      return {
        title:"操作",
        visible: false,
        disableSubmit: false,
        model: {},
        // 选择用户查询条件配置
        selectUserQueryConfig: [
          {key: 'phone', label: '电话'},
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        validatorRules:{},
        url: {
          add: baseUrl + "/hr-server/hrTaskRecord/add",
          edit: baseUrl + "/hr-server/hrTaskRecord/edit",
        },
        hrProjectMember: ''
      }
    },
    created () {
    },
    watch: {
    },
    computed: {
    },
    methods: {
      selectUser(value) {
        if (value?.length) {
          this.model.assignUid = value[0].id
        } else {
          this.model.assignUid = ''
        }
      },

      add (taskId) {
        //初始化默认值
        this.edit({ taskId });
      },
      edit (record) {
        this.model = Object.assign({}, record);
        this.visible = true;
      },
      close () {
        this.$emit('close');
        this.visible = false;
        this.$refs.form.clearValidate();
      },
      handleOk () {
        const that = this;
        // 触发表单验证
         this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
              method = 'put';
            }
            HttpAction(httpurl,this.model,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }else{
             return false;
          }
        })
      },
      handleCancel () {
        this.close()
      },
    }
  }
</script>

<style lang="less" scoped>
.select_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>