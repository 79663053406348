<template>
  <a-card style="background: #EFF1F4;" :bordered="false">
    <a-button @click="to" ghost type="primary">返回</a-button>
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper lc_title">
        <h3 class="rw_name">{{userInfo.username + reQuery.title}}</h3>
        <div class="rw_status" v-if="reQuery.type == 'detail'">
          <img :src="rePic">
        </div>
    </div>
    <a-tabs default-active-key="1" @change="callback" class="hist_main">
      <a-tab-pane key="1" tab="数据表单">
        <formcl :stepDisabled="stepDisabled" 
          :re_query="reQuery" 
          :formKey="formKey" 
          :formJson="formJson"
          :procData="procData"
          :histList="clHist">
        </formcl>
      </a-tab-pane>
      <a-tab-pane key="2" tab="实时流程图">
        <p v-if="reQuery.type != 'apply'">
          <a-icon type="border" :style="{ fontSize: '16px', color: 'gray' , backgroundColor: 'green'}"/>已完成
          <a-icon type="border" :style="{ fontSize: '16px', color: '#fad714', backgroundColor: '#ffa533' }"/>待处理
          <a-icon type="border" :style="{ fontSize: '16px', color: 'black', backgroundColor: '#ffffff' }"/>未完成
        </p>
        <p class="canvas" ref="canvas"></p>
      </a-tab-pane>
      <a-tab-pane key="3" tab="审批历史" v-if="reQuery.processInstanceId" 
        style="overflow: auto;height: calc(100vh - 190px);">
        <!-- reQuery.type != 'apply' -->
        <a-steps progress-dot :current="1" direction="vertical" style="padding:10px">
            <a-step v-for="item in histList" :key="item.id">
              <template slot="title">
                <span>{{item.endTime}}</span>
              </template>
              <template slot="description">
                <div class="content">
                  <h4>{{item.activityName || (item.activityType=='startEvent'?'提交申请':'')}}</h4>
                  <div class="cont-main">
                    <p><span style="opacity:0;">空</span>审批人: {{item.assignee}}</p>
                    <p>流程节点: {{item.activityName}}</p>
                    <p>审批意见: {{item.comment}}</p>
                    <p>开始时间: {{item.startTime}}</p>
                    <p>结束时间: {{item.endTime}}</p>
                    <p><span style="opacity:0;">空格</span>用时: {{dateDiff(item.startTime,item.endTime,'minute')}}分钟</p>
                  </div>
                </div>
              </template>
            </a-step>
        </a-steps>
      </a-tab-pane>
    </a-tabs>
  </a-card>

</template>

<script>
  import BpmnViewer from "bpmn-js/lib/Viewer";
  import formcl from './formcl';
  // import { filterObj } from '@/utils/util';
  import {setNodeColor, GetDateDiff} from './utils/lcUtil'
  import { 
    getActStartParam,
    // 表单信息
    getFormKey,
    getFormJson,
    historyList, //历史记录
    clHistLc,
    getModel, //获取模型
    getHightLine, //流程模型节点高亮
  } from '@/api/activitiApi'
  import { mapGetters, mapMutations } from "vuex"
  export default {
    // name:'流程历史-表单信息/流程记录',
    // mixins:[],
    components:{
      formcl
    },
    data() {
      return {
        // 高度
        innerHeight: window.innerHeight,
        // icon:'',
        histList:[],
        clHist:[],
        lignH:[],
        xml:'',
        bpmnViewer:null,
        formJson:{},
        formKey: '',
        reQuery: {},
        rePic: '',
        stepDisabled: false,
        procData:{}
      }
    },
    computed:{
    //   reQuery() {
    //     console.log(this.$route.query.type)
    //     return this.$route.query;
    //   },
    //   rePic() {
    //     let status = this.reQuery.status;
    //     let pic = status == 1 ? require('@/'+'assets/doing.png')
    //             : status == 2 ? require('@/'+'assets/succ.png')
    //             : require('@/'+'assets/wait.png')
    //     return pic
    //   },
      ...mapGetters(['userInfo'])
    },
    watch: {
        "$route.query": {
            deep: true,
            immediate: true,
            handler(val) {
                this.reQuery = {};
                this.formJson = {};
                this.procData = {};
                this.formKey = '';
                if (val.onlineDefinitionId) {
                    getActStartParam(val.onlineDefinitionId).then(res=>{
                        if (res.success) {
                            let obj = {
                                type:'apply',
                                title:`发起了${res.result.processName}`,
                                processInstanceId: '', // 流程记录&流程高亮的参数(申请过去不需要)
                                // formId:res.result.id, // 表单参数formKey&modelId
                                definitionId:res.result.definitionId, // 表单参数formKey&modelId&启动&表单保存时参数 key(以前)
                                name:res.result.processName, // 表单保存参数
                                processName:res.result.processName, // 表单保存参数
                                category:res.result.category, //启动参数
                                taskId: res.result.taskId,
                            }
                            if (val.onlineDefinitionType == 'edit') {
                                obj.isEdit = true;
                                obj.businessKey = val.businessKey // 表单回显的
                            }
                            this.reQuery = Object.assign({}, obj)
                            this.initData(this.reQuery.definitionId, this.reQuery.taskId);
                        }
                    })
                    // this.reQuery
                } else {
                    
                    this.reQuery = Object.assign({}, val)
                    let status = this.reQuery.status;
                    let pic = status == 1 ? require('@/'+'assets/doing.png')
                    : status == 2 ? require('@/'+'assets/succ.png')
                    : require('@/'+'assets/wait.png')
                    this.rePic = pic
                    
                    //获取表单数据formKey&modelId
                    this.initData(this.reQuery.definitionId, this.reQuery.taskId);
                    //流程记录
                    if(this.reQuery.processInstanceId) {
                        this.initLcInfo(this.reQuery.processInstanceId);
                        this.getclHistLc(this.reQuery.processInstanceId);
                        // 高亮
                        this.initHighLine(this.reQuery.processInstanceId);
                    }
                }
            }
        }
    },
    mounted() {
      
    },
    methods:{
      to() {
        this.$router.go(-1)
      },
      //用时
      dateDiff(et,st,str) {
        return GetDateDiff(et,st,str);
      },
      // tab切换
      callback(key) {
        console.log(key);
        if(key == 2) {
          this.$nextTick(() => {
            this.initBpmn(this.xml);
          })
        } 
        // else {
        //   document.getElementById('canvas').innerHTML = ''
        // }
      },
      // 获取表单数据formKey&modelId
      initData(definitionId, taskId) {
        if (!definitionId) return;
        getFormKey({
            processDefinitionId: definitionId,
            taskId: taskId
        }).then(res => {
            if(res.success) {
              console.log(res.result)
              //模型xml
              this.initModDefinition(res.result.modelId);
              this.formKey = res.result.formKey;
              this.procData = {
                processName:res.result.key,
                category:res.result.name
              }
              this.stepDisabled = (res.result.isEditable == '1') ? false : true;
              if (res.result.formKey) {
                  if (res.result.formKey.indexOf('@/') === -1) {
                    //json
                    this.initFormJSON(res.result.formKey)
                  }
              }
            }
        })
      },
      //获取表单JSON
      initFormJSON(formKey) {
        getFormJson({
          formCode:formKey
        }).then(res => {
          if(res.success) {
            this.formJson = res.result;
          }else {
            this.$message.info('未找到对应的表单数据');
          }
        })
      },
      //获取流程记录
      initLcInfo(instanceId) {
        let param = {
          processInstanceId: instanceId
        }
        historyList(param).then(res => {
          if(res.code === 200) {
            this.histList = res.rows.sort((a,b) => {return b.id - a.id;})
          }
        })
      },
      //获取审批流程
      getclHistLc(instanceId) {
        let param = {
          processInstanceId: instanceId
        }
        clHistLc(param).then(res => {
          if(res.code === 200) {
            this.clHist = res.rows
            // .sort((a,b) => {return b.id - a.id;})
          }
        })
      },
      // 获取历史-高亮
      initHighLine(instanceId) {
          getHightLine({
            instanceId: instanceId
          }).then(res => {
            if(res.success) {
              this.lignH = res.result;
            }
          })
      },
      // 获取流程(单个)xml
      initModDefinition(modelId) {
        getModel(modelId).then(res => {
          // this.initBpmn(res);
          if(res.success && res.result.length > 200) {
            this.xml = res.result;
          };
        })
      },
      async initBpmn(xml) {
        const _this = this;
        // 获取到属性ref为“canvas”的dom节点
        // const canvasEL = document.getElementById('canvas');
        const canvasEL = this.$refs.canvas;
        canvasEL.innerHTML = '';
        console.log(canvasEL)
        // console.log(this.bpmnViewer)
        this.bpmnViewer && this.bpmnViewer.destroy();
        this.bpmnViewer = new BpmnViewer({container: canvasEL});
        const viewer = this.bpmnViewer;
        try {
          const {warning} = await this.bpmnViewer.importXML(xml);
          const canvas = viewer.get('canvas');
          canvas.zoom('fit-viewport','auto');
          if(this.reQuery.processInstanceId) {
            setNodeColor(this.lignH['highLine'], "nodeLine", canvas);
            setNodeColor(this.lignH["highPoint"], "nodePoint", canvas);
            setNodeColor(this.lignH["waitingToDo"], "nodeWait", canvas);
            // setNodeColor(this.lignH["iDo"], "nodeIdo", canvas);
          }
          // console.log('success====',warning)
        } catch(err) {
          console.log('err======', err)
        }
        
      },

    }
  }
</script>

<style lang="less" scoped>
  .lc_title {
    position: relative;
    .rw_status {
      width: 100px;
      height: 100px;
      position: absolute;
      right: 0;
      top: -10px;
      z-index: 999;
      img{width: 100%;height: 100%;}
    }
  }
  .rw_name {
    text-align: center;
    font-size: 16px;
    // font-weight: bold;
  }
  /deep/ .ant-steps-item-content {width: 95%;}
  /deep/ .ant-tabs-nav .ant-tabs-tab-active {background-color: ivory;}
  /deep/ .ant-card-body {padding: 10px;}
  .hist_main {
    // height: calc(100vh - 100px);
    // display: flex;
    // margin: 30px 0;
    box-shadow: 0px 0px 5px 0px #000000;
    padding: 10px;
    background-color: aliceblue;
    .content {
      background-color: #fff;
      padding: 10px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      h4 {margin: 10px 0;}
      .cont-main {
        padding: 8px 16px;
        background-color: #ecf8ff;
        border-radius: 4px;
        border-left: 5px solid #50bfff;
        margin: 10px 0;
        color:#333;
      }
    }
  }

.canvas{
  margin-top: 20px;
  height:calc(100vh - 110px);
  // height: 90%;
  background: #fff;
}
/deep/ .bjs-powered-by {display: none;}
/deep/ .nodeLine.djs-connection {
  .djs-visual {
    > :nth-child(1) {
      stroke: green !important;
      // fill:green !important;
    }
  }
}
/deep/ .nodePoint:not(.djs-connection) {
  .djs-visual {
    > :nth-child(1) {
      stroke: gray !important;
      fill:#67c23a !important;
    }
  }
}
/deep/ .nodeWait:not(.djs-connection) {
  .djs-visual {
    > :nth-child(1) {
      stroke: #fad714 !important;
      fill:#ffa533 !important;
    }
  }
}
// /deep/ .nodeIdo:not(.djs-connection) {
//   .djs-visual {
//     > :nth-child(1) {
//       stroke: green !important;
//       fill:#a3d68e !important;
//     }
//   }
// }
</style>
