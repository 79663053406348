<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="任务名称">
              <a-input placeholder="请输入任务名称" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="负责人名称">
              <a-input placeholder="请输入负责人名称" v-model="queryParam.chargeName"></a-input>
            </a-form-item>
          </a-col>

          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <!-- <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button> -->
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator" style="margin: 8px 0px;">
      <a-button @click="to" ghost style="margin-right: 8px;" type="primary">返回</a-button>
      <a-button style="margin-right: 8px;" v-if="!queryParam.projectId" :disabled="permission" @click="handleAdd('')" type="primary" icon="plus">直接任务</a-button>
      <a-button :disabled="permission" @click="handleAdd(3)" ghost type="primary" icon="plus">定时任务</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay" v-if="!permission">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete" />删除</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
        <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
        >项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
				:scroll="{x: true}"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        class="j-table-force-nowrap"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
				@expand="expand"
      >
        <span slot="status" slot-scope="text">
          <a-tag :color="text | statusColor">{{ status[text] }}</a-tag>
        </span>
        <span slot="actStatus" slot-scope="text">
          <a-tag :color="text | act_status_color">
            <span>{{act_status[text]}}</span>
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a v-if="!permission" @click="handleAddTask(record)">添加普通子任务</a>
          <a-divider v-if="!permission" type="vertical" />
          <a @click="todoList(record)">{{ record.actStatus != 2 ? '查看处理' : '详情'}}</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item v-if="!permission">
                <a @click="handleAddTask(record, 3)">添加定时子任务</a>
              </a-menu-item>
              <a-menu-item v-if="record.executeType == 2">
                <a @click="handleStop(record)">暂停定时任务</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleTaskRecord(record)">日志</a>
              </a-menu-item>
              <a-menu-item v-if="!permission">
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
    <!-- 表单区域 -->
    <HrTaskFormModal ref="modalForm" @ok="modalFormOk"></HrTaskFormModal>
    <TaskRecordModal ref="taskRecordModal" @ok="modalFormOk"></TaskRecordModal>
  </a-card>
</template>

<script>
import { baseUrl } from "@/api/api.js"
import '@/assets/less/TableExpand.less'
import { GetAtion, HttpAction } from "@/utils/http"
import HrTaskFormModal from './modules/HrTaskFormModal'
import TaskRecordModal from './modules/TaskRecordModal'
import { JeecgListMixin } from '@/views/mixins/JeecgListMixin'
import { mapGetters } from "vuex"
import { whiteList, status } from "@/views/hrProjectManage/permission.js"
import {
  getProcessByKey,
  qdApply,
  getTodoList,
  getProcessByActi,
  getActStartProcess
} from '@/api/activitiApi';

export default {
  name: 'HrTask',
  mixins: [JeecgListMixin],
  components: {
    HrTaskFormModal,
    TaskRecordModal
  },
  filters: {
    statusColor(value) {
      const statusColor = {
        0: 'pink',
        1: 'pink',
        2: '#108ee9',
        3: '#87d068',
        4: '#f50',
        5: 'blue',
        6: 'purple'
      }
      return statusColor[value]
    },
    act_status_color(status) {
      const themeMap = {
        '0': 'volcano',
        '1': 'geekblue',
        '2': 'green'
      }
      return themeMap[status]
    }
  },
  data() {
    return {
      description: 'HrTask-分页列表查询',
      disableMixinCreated: true,
			queryParam: {
				pid: ''
			},
      status,
      // 表头
      columns: [
        {
          title: '任务名称',
          align: 'left',
          dataIndex: 'name',
        },
        {
          title: '任务状态',
          align: 'center',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '项目名称',
          align: 'center',
          dataIndex: 'projectName',
        },
        {
          title: '流程状态',
          dataIndex: 'actStatus',
          align:"center",
          scopedSlots: { customRender: 'actStatus' },
        },
        {
          title: '负责人',
          align: 'center',
          dataIndex: 'chargeName',
        },
        {
          title: '任务预算奖金',
          align: 'center',
          dataIndex: 'budgetAmount',
        },
        {
          title: '任务分配人',
          align: 'center',
          dataIndex: 'assignName',
        },
        {
          title: '任务描述',
          align: 'center',
          dataIndex: 'description'
        },
        {
          title: '计划开始时间',
          align: 'center',
          dataIndex: 'planStartTime',
        },
        {
          title: '计划结束时间',
          align: 'center',
          dataIndex: 'planEndTime',
        },
        {
          title: '实际开始时间',
          align: 'center',
          dataIndex: 'startTime',
        },
        {
          title: '实际结束时间',
          align: 'center',
          dataIndex: 'endTime',
        },
        
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
					fixed: 'right',
          scopedSlots: { customRender: 'action' },
					width: 220
        },
      ],
      url: {
        list: baseUrl + '/hr-server/hrTask/list',
        delete: baseUrl + '/hr-server/hrTask/delete',
        deleteBatch: baseUrl + '/hr-server/hrTask/deleteBatch',
        hrTaskTimingAttributeEdit: baseUrl + '/hr-server/hrTaskTimingAttribute/edit',
        exportXlsUrl: baseUrl + 'loan/loanContract/exportXls',
        importExcelUrl: baseUrl + 'loan/loanContract/importExcel',
        queryRolesByUserName: baseUrl + '/sfsj-server/sys/role/queryRolesByUserName',
        getDictItem: baseUrl + "/sfsj-server/sys/dict/getDictItem",
      },
      permission: true,
      act_status: {}
    }
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
    ...mapGetters(['userInfo'])
  },
  created() {
    this.getDictItem('act_status')
  },
  watch: {
    "$route.query": {
      handler(val) {
        this.queryParam.projectId = ""
        if (val.projectId) {
          this.queryParam.projectId = val.projectId
        }
        GetAtion(this.url.queryRolesByUserName, { userName: this.userInfo.username }).then(res=> {
          if (res.success) {
            let array = res.result.map(item => item.roleCode)
            for (let index = 0; index < array.length; index++) {
              const element = array[index];
              if (whiteList.indexOf(element) > -1) {
                this.permission = false
                break;
              }
            }
          } else {
            this.$message.warning('查询权限失败')
          }
          
        })
        this.loadData(1)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    to() {
      this.$router.go(-1)
    },
    handleStop(record) {
      this.loading = true
      HttpAction(this.url.hrTaskTimingAttributeEdit, {
        ...record.hrTaskTimingAttribute,
        status: 2
      },'put').then(res => {
        if (res.success) {
          this.modalFormOk()
          this.$message.success(res.message)
        } else {
          this.$message.warning(res.message)
        }
      }).catch(err=> {
        this.loading = false
      })
    },
    handleAddTask(record, activitiType) {
      if (record.chargeName != this.userInfo.username && this.permission) {
        this.$message.warning('暂无权限添加任务！')
        return;
      }
      if (activitiType) {
        this.$refs.modalForm.title = '新增定时任务'
        this.$refs.modalForm.add({
          projectId: record.projectId,
          pid: record.id,
          activitiType
        })
      } else {
        getActStartProcess('hr_task').then(res=>{
          if (res.success) {
            this.addApply(res.result, {
              projectId: record.projectId,
              pid: record.id,
              activitiType: record.projectId ? 1 : 2
            })
          }
        })
      }
      
      
    },
    // 发起申请
    addApply(record, query) {
      this.$router.push({
        path:'/activitiPage',
        query:{
          type:'apply',
          title:`发起了[${record.name}]`,
          processInstanceId: '', // 流程记录&流程高亮的参数(申请过去不需要)
          definitionId:record.id, // 获取表单formKey&modelId参数&启动&表单保存时参数
          // category:record.category, //启动&表单保存参数
          processName:record.name, //启动&表单保存参数
          ...query // 请求携带的路由参数，用于表单默认值
        }
      });
    },
    handleAdd: function (activitiType) {
      if (activitiType) {
        this.$refs.modalForm.add({
          projectId: this.queryParam.projectId,
          activitiType
        });
        this.$refs.modalForm.title = "新增定时任务";
      } else {
        getActStartProcess('hr_task').then(res=>{
          if (res.success) {
            this.addApply(res.result, {
              projectId: this.queryParam.projectId,
              pid: '',
              activitiType: this.queryParam.projectId ? 1 : 2
            })
          }
        })
      }
      
    },
    // 获取我的针对该条数据的待办列表
    todoList(record) {
      if (record.executeType == 2) {
        this.$refs.modalForm.edit(record);
        this.$refs.modalForm.title="详情";
        this.$refs.modalForm.disableSubmit = true && !this.setFormPermission;
      } else {
        getTodoList({
          pageNum: 1,
          pageSize: 10,
          // processName: "",
          type: "todo",
          businessKey: record.id
        }).then(res=> {
          if (res.success) {
            if (res.result.list.length) {
              let data = res.result.list[0]
              this.lookHandler(data)
            } else {
              this.handleDetail(record)
            }
          } else {
            this.$message.warning(res.message)
          }
        })
      }
      
    },
    // 查看并处理
    lookHandler(record) {
      this.$router.push({
        path:'/activitiPage',
        query:{ 
          type:'deal',
          title:`发起了[${record.taskName}]`,//标题
          processInstanceId: record.instanceId, // 流程记录&流程高亮的参数
          definitionId:record.definitionId, // 流程定义id
          taskId:record.taskId, //节点id
          businessKey:record.businessKey, // 表单json回显id
          // taskId: record.taskId
        }
      });
    },
    // 详情
    handleDetail(record) {
      this.getProc(record.activitiId).then(res => {
        this.$router.push({
          path: '/activitiPage',
          query:{ 
            type: 'detail',
            status: record.actStatus,
            title: `申请的[${res.processName}]`,//标题
            processInstanceId: res.instanceId, // 流程记录&流程高亮的参数
            definitionId: res.definitionId, // 表单formKey&modelId参数&启动&表单保存时参数 key(以前)
            businessKey: res.businessKey,
          }
        });
      });
    },
    //获取流程数据---编辑/查看/启动
    getProc(activitiId) {
      return getProcessByActi(activitiId).then(res => {
        if(res.success) {
          return res.result;
        }else {
          this.$message.warning(res.message);
        }
      })
    },
		loadData(arg) {
      // if (!this.queryParam.projectId) return;
      if(!this.url.list){
        this.$message.error("请设置url.list属性,,,,!")
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1;
      }
      var params = this.getQueryParams();//查询条件
      // params.pid = ''
      this.loading = true;
      GetAtion(this.url.list, params).then((res) => {
        if (res.success) {
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          let arr = res.result.records || res.result;
					this.dataSource = arr.map(item => {
            if (item.hasSub) {
              item.children = []
						  return item
            }
						return item
					})
          if(res.result.total)
          {
            this.ipagination.total = res.result.total;
          }else{
            this.ipagination.total = 0;
          }
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
        }
        if(res.code===510){
          this.$message.warning(res.message)
        }
        this.loading = false;
      })
    },
		expand(expanded, record) {
			if (expanded) {
				if(!this.url.list){
					this.$message.error("请设置url.list属性,,,,!")
					return
				}
				var params = this.getQueryParams();//查询条件
				params.pid = record.id
				params.pageNo = 1
				params.pageSize = 999
				this.loading = true;
				GetAtion(this.url.list, params).then((res) => {
					if (res.success) {
						//update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
						let arr = res.result.records || res.result;
						record.children = arr.map(item => {
							if (item.hasSub) {
                item.children = []
                return item
              }
              return item
						})
					}
					if(res.code===510){
						this.$message.warning(res.message)
					}
					this.loading = false;
				})
			}
		},
    handleTaskRecord(record) {
      this.$refs.taskRecordModal.queryParam.taskId = record.id
      this.$refs.taskRecordModal.visible = true
      this.$refs.taskRecordModal.title = record.name + '日志'
      this.$refs.taskRecordModal.loadData()
    },
    getDictItem(code) {
      //根据字典Code, 初始化字典数组
      GetAtion(this.url.getDictItem, { code }).then((res) => {
        if (res.success) {
          let arr = code.split(';')
          arr.forEach(item=>{
            res.result[item].forEach(element=>{
              this.$set(this[item], element.value, element.text)
            })
          })
        }
      })
    },
  },
}
</script>
<style scoped>
</style>