<template>
  <div class="app-container">
    <div ref="gantt" class="left-container"></div>
  </div>
</template>

<script>
import { gantt } from 'dhtmlx-gantt'  // 引入模块
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_terrace.css' //皮肤
import { status as statusTask } from "@/views/hrProjectManage/permission.js"
// import 'dhtmlx-gantt/codebase/locale/locale_cn'  // 本地化
// import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_tooltip.js' //任务条悬浮提示
export default {
  name: 'gantt',
  props: {
    tasks: {
      type: Object,
      default () {
        return {data: [], links: []}
      }
    }
  },
  data() {
    return {
      confirmLoading: false,
      dateType: 'month'
    }
  },
  methods: {
    //开始时间-结束时间参数
    DateDifference: function(strDateStart, strDateEnd) {
      var begintime_ms = Date.parse(new Date(strDateStart.replace(/-/g, '/'))) //begintime 为开始时间
      var endtime_ms = Date.parse(new Date(strDateEnd.replace(/-/g, '/')))   // endtime 为结束时间
      var date3 = endtime_ms - begintime_ms //时间差的毫秒数
      var days = Math.floor(date3 / (24 * 3600 * 1000))
      return days
    },
    initData: function() {
      let f = {
        id: 1,
        text: 'work甘特图',
        start_date: new Date('2021-12-24 00:00:00'),
        duration: 8,
        open: true, //默认打开，
        toolTipsTxt: 'xxxxxxxxxxxxxxxxx', 
        // progress: 0.1,
      }
      let data = [
        {
          toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
          text: '前端页面开发', // 任务名
          // start_date: '2021-12-24', // 开始时间
          id: 11, // 任务id
          // duration: 3, // 任务时长，从start_date开始计算
          parent: 1, // 父任务ID
          type: 4,
          open: true, //默认打开，
          // progress: 0.5,
          children: [
            {
              toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
              text: '计划时间', // 任务名
              start_date: new Date('2021-12-26 00:00:00'), // 开始时间
              id: 111, // 任务id
              duration: 3, // 任务时长，从start_date开始计算
              parent: 11, // 父任务ID
              type: 3,
              // progress: 0.5,
              user: '何兴达'
            },
            {
              toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
              text: '实际时间', // 任务名
              start_date: new Date('2021-12-26 00:00:00'), // 开始时间
              id: 112, // 任务id
              duration: 4, // 任务时长，从start_date开始计算
              parent: 11, // 父任务ID
              type: 1,
              // progress: 0.5,
              user: '何兴达'
            },
          ]
        },
        {
          toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
          text: '后端接口开发', // 任务名
          // start_date: '2021-12', // 开始时间
          id: 12, // 任务id
          // duration: 4, // 任务时长，从start_date开始计算
          parent: 1, // 父任务ID
          type: 4,
          user: '陈波，李刘松',
          open: true, //默认打开，
          children: [
            {
              toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
              text: '计划时间', // 任务名
              start_date: new Date('2021-12-24 00:00:00'), // 开始时间
              id: 121, // 任务id
              duration: 5, // 任务时长，从start_date开始计算
              parent: 12, // 父任务ID
              type: 3,
              // progress: 0.5,
              user: '陈波，李刘松'
            },
            {
              toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
              text: '实际时间', // 任务名
              start_date: new Date('2021-12-24 00:00:00'), // 开始时间
              id: 122, // 任务id
              duration: 4, // 任务时长，从start_date开始计算
              parent: 12, // 父任务ID
              type: 1,
              // progress: 0.5,
              user: '陈波，李刘松'
            },
          ]
        },
        {
          toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
          text: '共同调试', // 任务名
          start_date: new Date('2021-12-30 00:00:00'), // 开始时间
          id: 13, // 任务id
          duration: 2, // 任务时长，从start_date开始计算
          parent: 1, // 父任务ID
          type: 4,
          // progress: 0,
          user: '何兴达，陈波，李刘松',
          open: true, //默认打开，
          children: [
            {
              toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
              text: '计划时间', // 任务名
              start_date: new Date('2021-12-30 00:00:00'), // 开始时间
              id: 131, // 任务id
              duration: 2, // 任务时长，从start_date开始计算
              parent: 13, // 父任务ID
              type: 3,
              // progress: 0.5,
              user: '何兴达，陈波，李刘松'
            },
            {
              toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
              text: '实际时间', // 任务名
              start_date: new Date('2021-12-30 00:00:00'), // 开始时间
              id: 132, // 任务id
              duration: 2, // 任务时长，从start_date开始计算
              parent: 13, // 父任务ID
              type: 1,
              // progress: 0.5,
              user: '何兴达，陈波，李刘松'
            },
          ]
        },
      ]
      data.forEach(item => {
        if (item.children && item.children.length) {
          if (item.children[1].duration - item.children[0].duration > 0) {
            item.children[1].type = 5
          }
        }
      })
      let tasksData = filterArr(data)
      function filterArr(arr) {
        let newArr = []
        arr.forEach(item=> {
          if (item.children && item.children.length) {
            let children = item.children
            delete item.children
            newArr.push(item)
            newArr = newArr.concat(filterArr(children))
          } else {
            newArr.push(item)
          }
          
        })
        return newArr
      }
      tasksData.unshift(f)
      console.log('tasksData------', tasksData);
      this.tasks.data = tasksData.map(function(current, ind, arry) {
        var newObj = {}
        if (current.type) { //存在type字段 说明非一级菜单，判断阶段的具体类型 设置不同颜色
          if (current.type == 1) { //冒烟
            newObj = Object.assign({}, current, { 'color': '#62ddd4' })
          } else if (current.type == 2) { //单元
            newObj = Object.assign({}, current, { 'color': '#fec0dc' })
          } else if (current.type == 3) { //回归
            newObj = Object.assign({}, current, { 'color': '#fcca02' })
          } else if (current.type == 4){
            newObj = Object.assign({}, current, { 'color': '#d1a6ff' })
          } else if (current.type == 5){
            newObj = Object.assign({}, current, { 'color': '#d81e14' })
          }
        } else { //一级菜单是蓝色的
          newObj = Object.assign({}, current, { 'color': '#5692f0' })
        }

        return newObj
      })
      // this.tasks.data = [
      //   {
      //     id: 1,
      //     text: 'work甘特图',
      //     start_date: new Date('2021-12-24 00:00:00'),
      //     duration: 8,
      //     open: true, //默认打开，
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx',
      //     // progress: 0.1
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '前端页面开发', // 任务名
      //     // start_date: '2021-12-24', // 开始时间
      //     id: 11, // 任务id
      //     // duration: 3, // 任务时长，从start_date开始计算
      //     parent: 1, // 父任务ID
      //     type: 4,
      //     open: true, //默认打开，
      //     // progress: 0.5,
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '计划时间', // 任务名
      //     start_date: new Date('2021-12-26 00:00:00'), // 开始时间
      //     id: 111, // 任务id
      //     duration: 3, // 任务时长，从start_date开始计算
      //     parent: 11, // 父任务ID
      //     type: 3,
      //     // progress: 0.5,
      //     user: '何兴达'
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '实际时间', // 任务名
      //     start_date: new Date('2021-12-26 00:00:00'), // 开始时间
      //     id: 112, // 任务id
      //     duration: 4, // 任务时长，从start_date开始计算
      //     parent: 11, // 父任务ID
      //     type: 5,
      //     // progress: 0.5,
      //     user: '何兴达'
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '后端接口开发', // 任务名
      //     // start_date: '2021-12', // 开始时间
      //     id: 12, // 任务id
      //     // duration: 4, // 任务时长，从start_date开始计算
      //     parent: 1, // 父任务ID
      //     type: 4,
      //     user: '陈波，李刘松',
      //     open: true, //默认打开，
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '计划时间', // 任务名
      //     start_date: new Date('2021-12-24 00:00:00'), // 开始时间
      //     id: 121, // 任务id
      //     duration: 5, // 任务时长，从start_date开始计算
      //     parent: 12, // 父任务ID
      //     type: 3,
      //     // progress: 0.5,
      //     user: '陈波，李刘松'
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '实际时间', // 任务名
      //     start_date: new Date('2021-12-24 00:00:00'), // 开始时间
      //     id: 122, // 任务id
      //     duration: 4, // 任务时长，从start_date开始计算
      //     parent: 12, // 父任务ID
      //     type: 1,
      //     // progress: 0.5,
      //     user: '陈波，李刘松'
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '共同调试', // 任务名
      //     start_date: new Date('2021-12-30 00:00:00'), // 开始时间
      //     id: 13, // 任务id
      //     duration: 2, // 任务时长，从start_date开始计算
      //     parent: 1, // 父任务ID
      //     type: 4,
      //     // progress: 0,
      //     user: '何兴达，陈波，李刘松',
      //     open: true, //默认打开，
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '计划时间', // 任务名
      //     start_date: new Date('2021-12-30 00:00:00'), // 开始时间
      //     id: 131, // 任务id
      //     duration: 2, // 任务时长，从start_date开始计算
      //     parent: 13, // 父任务ID
      //     type: 3,
      //     // progress: 0.5,
      //     user: '何兴达，陈波，李刘松'
      //   },
      //   {
      //     toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //     text: '实际时间', // 任务名
      //     start_date: new Date('2021-12-30 00:00:00'), // 开始时间
      //     id: 132, // 任务id
      //     duration: 2, // 任务时长，从start_date开始计算
      //     parent: 13, // 父任务ID
      //     type: 1,
      //     // progress: 0.5,
      //     user: '何兴达，陈波，李刘松'
      //   },
        

      //   // {
      //   //   id: 1,
      //   //   text: '计划时间',
      //   //   start_date: '2020-04-08',
      //   //   duration: 10,
      //   //   open: true, //默认打开，
      //   //   toolTipsTxt: 'xxxxxxxxxxxxxxxxx'
      //   // },
      //   // {
      //   //   toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //   //   text: '冒烟阶段', // 任务名
      //   //   start_date: '2020-04-08', // 开始时间
      //   //   id: 11, // 任务id
      //   //   duration: 3, // 任务时长，从start_date开始计算
      //   //   parent: 1, // 父任务ID
      //   //   type: 1,
      //   //   progress: 0.8,
      //   // },
      //   // {
      //   //   toolTipsTxt: '',
      //   //   text: '单元测试', // 任务名
      //   //   start_date: '2020-04-11', // 开始时间
      //   //   id: 12, // 任务id
      //   //   duration: 2, // 任务时长，从start_date开始计算
      //   //   parent: 1, // 父任务ID
      //   //   type: 2

      //   // },
      //   // {
      //   //   toolTipsTxt: '',
      //   //   text: '回归测试', // 任务名
      //   //   start_date: '2020-04-13', // 开始时间
      //   //   id: 13, // 任务id
      //   //   duration: 4, // 任务时长，从start_date开始计算
      //   //   parent: 1, // 父任务ID
      //   //   type: 3

      //   // }, 
      //   // {
      //   //   toolTipsTxt: '',
      //   //   text: '阶段四', // 任务名
      //   //   start_date: '2020-04-13', // 开始时间
      //   //   id: 14, // 任务id
      //   //   duration: 4, // 任务时长，从start_date开始计算
      //   //   parent: 1, // 父任务ID
      //   //   type: 4

      //   // },
      //   // //========================
      //   // {
      //   //   id: 2,
      //   //   text: '实际时间',
      //   //   start_date: '2020-04-08',
      //   //   duration: 8,
      //   //   open: true, //默认打开，才可隐藏左侧表格
      //   //   toolTipsTxt: 'xxxxxxxxxxxxxxxxx',
      //   //   state: 'default',
      //   //   // color:"#409EFF",
      //   //   // progress: 0.6
      //   // },
      //   // {
      //   //   toolTipsTxt: 'xxxxxxxxxxxxxxxxx父任务01-001',
      //   //   text: '冒烟阶段', // 任务名
      //   //   start_date: '2020-04-08', // 开始时间
      //   //   id: 21, // 任务id
      //   //   duration: 2, // 任务时长，从start_date开始计算
      //   //   parent: 2, // 父任务ID
      //   //   type: 1
      //   // },
      //   // {
      //   //   toolTipsTxt: '',
      //   //   text: '单元测试', // 任务名
      //   //   start_date: '2020-04-09', // 开始时间
      //   //   id: 22, // 任务id
      //   //   duration: 2, // 任务时长，从start_date开始计算
      //   //   parent: 2, // 父任务ID
      //   //   type: 2
      //   // },
      //   // {
      //   //   toolTipsTxt: '',
      //   //   text: '回归测试', // 任务名
      //   //   start_date: '2020-04-11', // 开始时间
      //   //   id: 23, // 任务id
      //   //   duration: 2, // 任务时长，从start_date开始计算
      //   //   parent: 2, // 父任务ID
      //   //   type: 3
      //   // }

      // ].map(function(current, ind, arry) {
      //   var newObj = {}
      //   if (current.type) { //存在type字段 说明非一级菜单，判断阶段的具体类型 设置不同颜色
      //     if (current.type == 1) { //冒烟
      //       newObj = Object.assign({}, current, { 'color': '#62ddd4' })
      //     } else if (current.type == 2) { //单元
      //       newObj = Object.assign({}, current, { 'color': '#fec0dc' })
      //     } else if (current.type == 3) { //回归
      //       newObj = Object.assign({}, current, { 'color': '#fcca02' })
      //     } else if (current.type == 4){
      //       newObj = Object.assign({}, current, { 'color': '#d1a6ff' })
      //     } else if (current.type == 5){
      //       newObj = Object.assign({}, current, { 'color': '#d81e14' })
      //     }
      //   } else { //一级菜单是蓝色的
      //     newObj = Object.assign({}, current, { 'color': '#5692f0' })
      //   }

      //   return newObj
      // })
    },
    changeDate(dateType) {
      this.dateType = dateType
      this.init()
    },
    init() {
      // this.initData();
      
        gantt.attachEvent('onTaskSelected', (id) => {
          let task = gantt.getTask(id);
          this.$emit('task-selected', task);
        });

        gantt.attachEvent('onTaskIdChange', (id, new_id) => {
          if (gantt.getSelectedId() == new_id) {
            let task = gantt.getTask(new_id);
            this.$emit('task-selected', task);
          }
        });
        /*
        * 冒烟：fcca02    单元：fec0dc     回归：62ddd4   阶段：d1a6ff
        * */
        //设置图表区域的日期坐标最大值 var date = new Date(dateString.replace(/-/,"/"))
        //  gantt.config.start_date = new Date("2020-04-08".replace(/-/,"/")) ;
        //gantt.config.end_date = new Date("2020-04-18".replace(/-/,"/")) ; //结束时间需要+1天

        //自适应甘特图的尺寸大小, 使得在不出现滚动条的情况下, 显示全部任务
        gantt.config.autosize = true
        //只读模式
        gantt.config.readonly = true
        //是否显示左侧树表格
        gantt.config.show_grid = true
        //表格列设置
        gantt.config.columns = [
          { name: 'text', label: '阶段名字', tree: true, width: '160' },
          {
            name: 'duration', label: '时长', align: 'center', template: function(obj) {
              return obj.duration + '天'
            }
          },
          {name:"chargeName", label:"负责人", align: "center", width: '160' },
          
          /*{name:"start_date", label:"开始时间", align: "center" },

          {name:"end_date",   label:"结束时间",   align: "center" },*/
        ]
        //时间轴图表中，如果不设置，只有行边框，区分上下的任务，设置之后带有列的边框，整个时间轴变成格子状。
        gantt.config.show_task_cells = true

        //设置x轴日期
        // gantt.config.scale_unit = 'year' // 官方文档表示该方法已弃用
        // gantt.config.step = 1 // 官方文档表示该方法已弃用
        // gantt.config.date_scale = '%Y' + '年' // 官方文档表示该方法已弃用

        //当task的长度改变时，自动调整图表坐标轴区间用于适配task的长度
        gantt.config.fit_tasks = true
        // 在时间线上增加一行显示星期
        // gantt.config.subscales = [ // 官方文档表示该方法已弃用
        //   // {unit:"day",  step:1, date:"星期"+"%D" },
        //   { unit: 'week', step: 1, template:weekScaleTemplate },
        //   { unit: 'month', step: 1, date: '%m' + '月' }
        // ]
        // gantt.config.scales = [
        //   {unit: "year", step: 1, format: "%Y"},
        //   {unit: "month", step: 1, format: "%m" + "月"},
        //   // {unit: "week", step: 1, format: weekScaleTemplate},
        //   // {unit: "day", step: 1, format: "%D", css:daysStyle }
        // ];
        var monthScaleTemplate = function (date) {
          var dateToStr = gantt.date.date_to_str("%M");
          var endDate = gantt.date.add(date, 2, "month");
          return dateToStr(date) + " - " + dateToStr(endDate);
        };

        // gantt.config.scales = [
        //   // {unit: "year", step: 1, format: "%Y" + '年'}, // 年
        //   // {unit: "month", step: 3, format: monthScaleTemplate},
        //   // {unit: "month", step: 1, format: "%m" + '月'}, // 月
        //   {unit: "day", step: 1, format: "%d" + '日'} // 日
        // ];
        if (this.dateType === 'day') {
          gantt.config.scales = [
            { unit: "month", step: 1, format: "%m" + '月' },
            { unit: "day", step: 1, format: "%d" + '日' }
          ];
        } else if (this.dateType === 'month') {
          gantt.config.scales = [
            {unit: "year", step: 1, format: "%Y" + '年'},
            {unit: "month", step: 1, format: "%m" + '月'},
          ]
        } else if (this.dateType === 'year') {
          gantt.config.scales = [
            {unit: "year", step: 1, format: "%Y" + '年'},
          ]
        }
        gantt.config.scale_height = 90;
        //时间轴图表中，任务条形图的高度
        gantt.config.bar_height = 46
        //时间轴图表中，甘特图的高度
        gantt.config.row_height = 50
        gantt.config.date_format = "%Y-%m-%d %H:%i:%s";
        //从后端过来的数据格式化
        // gantt.config.xml_date = '%Y-%m-%d'
        // gantt.config.xml_date = '%Y-%m' // 已弃用
        //任务条显示内容
        gantt.templates.task_text = function(start, end, task) {
          return (statusTask[task.status] || '未启动') + ' (完成度：' + parseInt((task.progress * 100)) + '%)'
        }
        // gantt.templates.task_class = function(start, end, task){return "xx";};
        //悬浮
        gantt.templates.tooltip_text = function(start, end, task) {
          //return "<b>任务名称:</b> "+task.text+"<br/><b>时长:</b> " + task.duration+"<br/><b>说明:</b>" +task.toolTipsTxt;
          return '<span style=\'font-size: 14px\'>' + task.toolTipsTxt + '</span>'
        }

        gantt.templates.scale_cell_class = function(date) {
          /*if(date.getDay()== 0 || date.getDay()== 6){
            return "weekend";
          }*/
          return 'weekend'
        }
        //任务栏周末亮色
        /*gantt.templates.task_cell_class = function(item,date){
          if(date.getDay()== 0 || date.getDay()== 6){
            return "weekend";
          }
        };*/
        //任务条上的文字大小 以及取消border自带样式
        gantt.templates.task_class = function(start, end, item) {
          return item.$level == 0 ? 'firstLevelTask' : 'secondLevelTask'
        }
        
        // 初始化
        gantt.init(this.$refs.gantt)
        // 数据解析
        console.log('数据解析----', this.tasks);
        gantt.parse(this.tasks)
      
    }
  },
}
</script>

<style lang="less">
  @import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
  // .firstLevelTask {
  //   border: none;
  // .gantt_task_content{
  //     // font-weight: bold;
  //     font-size: 13px;
  //   }
  // }

  // .secondLevelTask {
  //   border: none;
  // }

  // .thirdLevelTask {
  //   border: 2px solid #da645d;
  //   color: #da645d;
  //   background: #da645d;
  // }

  // .milestone-default {
  //   border: none;
  //   background: rgba(0, 0, 0, 0.45);
  // }

  // .milestone-unfinished {
  //   border: none;
  //   background: #5692f0;
  // }

  // .milestone-finished {
  //   border: none;
  //   background: #84bd54;
  // }

  // .milestone-canceled {
  //   border: none;
  //   background: #da645d;
  // }

  // html, body {
  //   margin: 0px;
  //   padding: 0px;
  //   height: 100%;
  //   overflow: hidden;
  // }

  // // .container {
  // //   height: 900px;
  // //   .left-container {
  // //     height: 100%;
  // //   }
  // // }

  // // .left-container {
  // //   height: 600px;
  // // }

  // .gantt_scale_line {
  //   border-top: 0;
  // }

  // .weekend {
  //   //background:#f4f7f4!important;
  //   color: #000000 !important;
  // }

  // .gantt_selected .weekend {
  //   background: #f7eb91 !important;;
  // }

  // .gantt_task_content {
  //   text-align: left;
  //   padding-left: 10px;
  // }

  // //上面任务条样式
  // .gantt_task_scale {
  //   height: 45px !important;
  // }

  // .gantt_task .gantt_task_scale .gantt_scale_cell {
  //   line-height: 30px !important;
  //   height: 28px !important;
  // }
</style>
