<template>
  <j-modal
    :title="title"
    :visible="visible"
    :fullscreen="true"
    switchFullscreen
    @cancel="visible = false"
    :footer="false"
    cancelText="关闭">
    <!-- 查询区域 -->
    <!-- <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="项目名称">
              <a-input placeholder="请输入项目名称" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="负责人名称">
              <a-input placeholder="请输入负责人名称" v-model="queryParam.chargeName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div> -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete" />删除</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
        <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
        >项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->
    <!-- 表单区域 -->
    <TaskRecordForm ref="modalForm" @ok="modalFormOk"></TaskRecordForm>
  </j-modal>
</template>

<script>
import { baseUrl } from "@/api/api.js"
import '@/assets/less/TableExpand.less'
import { GetAtion, HttpAction } from "@/utils/http"
import TaskRecordForm from './TaskRecordForm'
import { JeecgListMixin } from '@/views/mixins/JeecgListMixin'
const status = {
  1:'未开始',
  2:'进行中',
  3:'已结束',
  4:'超时'
}
export default {
  name: 'HrTask',
  mixins: [JeecgListMixin],
  components: {
    TaskRecordForm
  },
  data() {
    return {
      description: 'TaskRecordModal-分页列表查询',
      visible: false,
      title: '日志',
			queryParam: {
				taskId: ''
			},
      // 表头
      columns: [
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'createBy',
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'createTime',
        },
        {
          title: '任务记录',
          align: 'center',
          dataIndex: 'note',
        },
        {
          title: '使用工时',
          align: 'center',
          dataIndex: 'hour',
          customRender: function (t, r, index) {
            return t + '小时'
          },
        },
        
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
					fixed: 'right',
          scopedSlots: { customRender: 'action' },
					width: 200
        },
      ],
      url: {
        list: baseUrl + '/hr-server/hrTaskRecord/list',
        delete: baseUrl + '/hr-server/hrTaskRecord/delete',
        deleteBatch: baseUrl + '/hr-server/hrTaskRecord/deleteBatch',
        exportXlsUrl: baseUrl + 'loan/loanContract/exportXls',
        importExcelUrl: baseUrl + 'loan/loanContract/importExcel',
      },
    }
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
  },
  methods: {
    loadData(arg) {
      if(!this.queryParam.taskId) return;
      if(!this.url.list){
        this.$message.error("请设置url.list属性,,,,!")
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1;
      }
      
      var params = this.getQueryParams();//查询条件
      this.loading = true;
      GetAtion(this.url.list, params).then((res) => {
        if (res.success) {
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.result.records||res.result;
          if(res.result.total)
          {
            this.ipagination.total = res.result.total;
          }else{
            this.ipagination.total = 0;
          }
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
        }
        if(res.code===510){
          this.$message.warning(res.message)
        }
        this.loading = false;
      })
    },
    handleAdd: function () {
      this.$refs.modalForm.add(this.queryParam.taskId);
      this.$refs.modalForm.title = "新增";
      this.$refs.modalForm.disableSubmit = false;
    },
  },
}
</script>
<style scoped>
</style>