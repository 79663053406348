
/******
 * 前二种方式配合使用 bpmn(编辑模式)
 * 后一种方式 使用 bpmn(预览模式)
 */
export function getByColor(data){
    let ColorJson = []
    for (let k in data['highLine']) {
        let par = {
            'name': data['highLine'][k],
            'stroke': 'green',
            'fill': 'green'
        }
        ColorJson.push(par)
    }
    for (let k in data['highPoint']) {
        let par = {
            'name': data['highPoint'][k],
            'stroke': 'gray',
            'fill': '#67c23a'

        }
        ColorJson.push(par)
    }
    for (let k in data['iDo']) {
        let par = {
            'name': data['iDo'][k],
            'stroke': 'green',
            'fill': '#a3d68e'
        }
        ColorJson.push(par)
    }
    for (let k in data['waitingToDo']) {
        let par = {
            'name': data['waitingToDo'][k],
            'stroke': 'green',
            'fill': '#fad714'
        }
        ColorJson.push(par)
    }
    return ColorJson
}

export function setColor(json, bpmnModeler) {
    console.log('json, bpmnModeler====',json, bpmnModeler)
    // if(json.name != null) {
        let elementRegistry = bpmnModeler.get('elementRegistry')
        let elementToColor = elementRegistry.get(json.name)
        console.log('elementRegistry===', elementRegistry)
        console.log('elementToColor===', elementToColor)
        let modeling = bpmnModeler.get("modeling");
        console.log('modeling====',modeling)
        if (elementToColor) {
            modeling.setColor([elementToColor], {
                stroke: json.stroke,
                fill: json.fill
            })
        }
    // }
}

// 设置高亮颜色的class
export function setNodeColor(nodeCodes, colorClass, canvas) {
    for (let i = 0; i < nodeCodes.length; i++) {
        nodeCodes[i] && canvas.addMarker(nodeCodes[i], colorClass);
    }
}



/* 
* 获得时间差,时间格式为 年-月-日 小时:分钟:秒 或者 年/月/日 小时：分钟：秒 
* 其中，年月日为全格式，例如 ： 2010-10-12 01:00:00 
* 返回精度为：秒，分，小时，天
*/

export function GetDateDiff(startTime, endTime, diffType) {
    //将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式 
    startTime = startTime.replace(/-/g, "/");
    endTime = endTime.replace(/-/g, "/");
  
    //将计算间隔类性字符转换为小写
    diffType = diffType.toLowerCase();
    var sTime = new Date(startTime);      //开始时间
    var eTime = new Date(endTime);  //结束时间
    //作为除数的数字
    var divNum = 1;
    switch (diffType) {
        case "second":
            divNum = 1000;
            break;
        case "minute":
            divNum = 1000 * 60;
            break;
        case "hour":
            divNum = 1000 * 3600;
            break;
        case "day":
            divNum = 1000 * 3600 * 24;
            break;
        default:
            break;
    }
    return parseInt((eTime.getTime() - sTime.getTime()) / parseInt(divNum));
}
