<template>
  <div class="form_model">
    <div class="lc_sj">
        <!-- <a-button v-print="'#printContent'" ghost type="primary" @click="prints">打印</a-button> -->
        <div id="printContent">
            <!-- <KFormBuild 
            v-if="!lcModa.visible"
            :disabled="disabled || isArchive"
            :defaultValue="defaultValue"
            :value="reFormData"
            :formDesignCode="formJson.formDesignCode"
            @change="changeKFormBuild"
            :histList="histList"
            ref="KFB"></KFormBuild> -->
            <component
                :disabled="disabled"
                v-if="lcModa.visible"
                :formType="lcModa.type"
                :is="lcModa.formComponent"
                :processData="lcModa.processData"
                :reQuery="reQuery"
                :isNew="lcModa.isNew"
                :setFormPermission="setFormPermission"
                :task="lcModa.task"
                :histList="histList"
                @close="lcModa.visible=false,lcModa.disabled = false">
            </component>
        </div>
    </div>
    <div class="lc_cz" v-if="!isCust && reQuery.type!='detail' && bntVisible">
      <span class="table-page-search-submitButtons" style="float: right; padding: 10px 0;">
        <a-col :md="24" :sm="24" v-if="reQuery.type=='apply'">
          <a-button type="primary" :loading="startUpLoading" ghost style="margin-right: 10px" @click="startUp('save')">暂存表单</a-button>
          <a-button type="primary" :loading="startUpLoading" ghost style="left: 10px" @click="startUp('start')">启动</a-button>
        </a-col>
        <a-col :md="24" :sm="24" v-if="reQuery.type=='deal'">
          <div style="display: flex;">
            <div style="display: flex;">
              <span style="width: 100px;">审批意见:</span>
              <a-textarea style="margin-bottom:20px;"
                :autosize="{ minRows: 2, maxRows: 6 }"
                v-model="dealValue"
                placeholder="请输入审批意见"
              />
            </div>
            <div v-if="definiteBtn.button">
              <a-button style="margin-left: 10px"
                v-for="item in definiteBtn.button"
                :key="item.name"
                :type="item.value == 'true'?'primary':''" 
                @click="definiteHandler(item)">{{item.name}}</a-button>
            </div>
            <div v-else>
              <a-button type="primary"  style="margin-left: 10px" @click="compHandler(true)">同意</a-button>
              <a-button style="margin-left: 20px" @click="compHandler(false)">不同意</a-button>
            </div>
          </div>
          
        </a-col>
      </span>
    </div>
    <div class="lc_cz" v-if="!isCust && reQuery.type==='detail' && setFormPermission && !isArchive">
      <span class="table-page-search-submitButtons" style="float: right; padding: 10px 0;">
        <a-col :md="24" :sm="24">
          <a-button 
            type="primary" 
            :loading="startUpLoading" 
            ghost 
            style="margin-right: 10px" 
            @click="startUp('save')">修改表单</a-button>
        </a-col>
      </span>
    </div>
  </div>
</template>

<script>
import {
  setFormData,
  formSave,
  custFormSave,
  getFormData,
  getCustFormData,
  getUserRoleSet,
  qdApply,
  completeHandler,
  getHandlerBtn,
  getProcessByActi
} from '@/api/activitiApi'
import { mapGetters } from 'vuex';
import { activitiMixin } from '@/views/activiti/mixins/activitiMixin'
  export default {
    // name:'表单处理',
    mixins: [activitiMixin],
    props:{
      stepDisabled:{
        type: Boolean,
        default: false
      },
      formKey:String,
      formJson:{
        type: Object,
        default: () => ({})
      },
      re_query:{
        type: Object,
        default: () => ({})
      },
      procData:{
        type: Object,
        default: () => ({})
      },
      //审批历史
      histList:{
        type: Array,
        default: () => ([])
      }
    },
    components: {
    },
    data() {
      return {
        model:{},
        // 默认字段设置值
        defaultValue:{
          // name:'lp'
        },
        bntVisible: true,
        //数据值
        jsonData:{},
        //审批意见
        dealValue:'',
        confirmLoading: false,
        startUpLoading: false,
        lcModa: {
            formComponent: null,
            visible: false,
            type: 'add',
            id: '',
            task:false //是否处理流程
        },
        //自定义按钮
        definiteBtn:{},
        permission: [
          'admin',
          'test_admin'
        ],
        setFormPermission: false,
        //是否归档
        isArchive:false
      }
    },
    //关闭tab菜单栏的方法
    inject:['closeCurrent'],
    computed:{
      reQuery() {
        return this.re_query;
      },
      reFormData() {
        let formData = this.formJson.formJson;
        return formData ? JSON.parse(formData) : {}
      },
      disabled: {
        get() {
          let permission = !this.setFormPermission
          
          return (this.reQuery.type != 'apply') && this.stepDisabled && permission;
        },
        set(value) {
        }
      },
      //是否是自定义表单
      isCust() {
        return this.formKey && this.formKey.indexOf('@/') >-1
      },
      ...mapGetters(['userInfo'])
    },
    watch:{
      reFormData: {
          deep: true,
          handler(val) {
              if (val.config && val.config.reinforceCssStyle) {
                  this.loadStyle(val.config.reinforceCssStyle, this.formJson.formDesignCode)
              }
          }
      },
      formJson:{
        handler(val,old) {
          let len = Object.keys(val).length
          if(len && this.reQuery.type!='apply') {
            this.initFormData()
          } else if(len && this.reQuery.businessKey) {
              this.initFormData()
          }
        },
        deep:true,
        immediate:true
      },
      reQuery: {
        handler(val,old) {
          // this.$nextTick(() => {
          //   if(this.reQuery.type == 'deal') {
          //     this.initHandlerBtn();
          //   }
          // })
          this.lcModa = {
            formComponent: null,
            visible: false,
            type: 'add',
            id: '',
            task:false //是否处理流程
          }
          getUserRoleSet({ username: this.userInfo.username }).then(res=>{
            let p = false
            if (res && typeof res === 'object' && res.length) {
              for (let index = 0; index < this.permission.length; index++) {
                const element = this.permission[index];
                if (res.indexOf(element) !== -1) {
                  p = true
                  break;
                }
              }
            }
            this.setFormPermission = p
          })
        },
        deep:true,
        immediate:true
      },
      formKey:{
        handler(val,old) {
          if(val.indexOf('@/') > -1) {
            if(this.reQuery.type == 'apply') {
              // 申请
              this.lcModa.formComponent = this.getFormComponent(val).component
              // this.lcModa.title = '发起流程：' + this.reQuery.name
              this.lcModa.isNew = true
              this.lcModa.processData = {}
              this.lcModa.visible = true
              this.lcModa.disabled = this.disabled
              this.lcModa.setFormPermission = this.setFormPermission
              this.lcModa.type = 'add'
              if(this.reQuery.isEdit) {
                // this.lcModa.title = '修改流程业务信息：' + this.reQuery.name
                this.lcModa.isNew = false
                this.lcModa.processData = {tableId: this.reQuery.businessKey}
              }
            }else {
              // 查看
              this.lcModa.formComponent = this.getFormComponent(val).component
              // this.lcModa.title = '查看流程业务信息：' + this.reQuery.title
              this.lcModa.isNew = false
              this.lcModa.processData = {
                tableId: this.reQuery.businessKey,
                ...this.procData
              }
              this.lcModa.visible = true
              this.lcModa.disabled = this.disabled
              this.lcModa.setFormPermission = this.setFormPermission
              this.lcModa.task = this.reQuery.type == 'deal'
            }
          }else {
            if(!!val && this.reQuery.type == 'deal') {
              this.initHandlerBtn();
            }
          }
        },
        immediate:true
      },
    },
    methods:{
      // 管理员修改表单
      submitFormPermission() {

      },
      // 打印操作
      prints(){
        this.bntVisible = false
      },
      //动态加载css 
      loadStyle(css, code) {
          var style = document.createElement('style');
          style.type = 'text/css';
          // style.rel = 'stylesheet';
          style.innerHTML = css
          style.id = code; 
          document.getElementsByTagName('head').item(0).appendChild(style)
      },
      changeKFormBuild(value, key) {
        if (!value) return;
        let extendField = this.reFormData.config.extendField;
        if (extendField && extendField.length) {
          for (let index = 0; index < extendField.length; index++) {
            const element = extendField[index];
            // 查看是否包含该字段
            if (element.extendId && element.extendId == key) {
              getFormData({
                cgformHeadId: element.tabHeaderId,
                businessKey: value
              }).then(res=>{
                if (res.success && res.result) {
                  let obj = {};
                  for (const keys in res.result) {
                    if (Object.hasOwnProperty.call(res.result, keys)) {
                      const objItem = res.result[keys];
                      obj['_' + key + '_' + keys] = objItem;
                    }
                  }
                  this.$refs.KFB.setData(obj)
                }
              })
            }
          }
        }
      },
      //获取表单数据
      initFormData() {
        let jsonObj = this.formJson;
        let isCustUrl = !!jsonObj.queryUrl;
        if(isCustUrl) {
          // 自定义获取表单数据
          getCustFormData({
            url:jsonObj.queryUrl,
            query:{
              id: this.reQuery.businessKey
            }
          }).then(res => {
            if(res.success) {
              this.$refs.KFB.setData(res.result)
            }
          })
        }else {
          getFormData({
            cgformHeadId:jsonObj.cgformHeadId,
            businessKey:this.reQuery.businessKey
          }).then(res => {
            if(res.success) {
              this.model = Object.assign({}, res.result);
              //是否归档
              this.isArchive = res.result.is_archive == 1;
              this.$refs.KFB.setData(res.result);
              for (const key in this.model) {
                  if (Object.hasOwnProperty.call(this.model, key)) {
                      const element = this.model[key];
                      this.changeKFormBuild(element, key)
                  }
              }
            }
          })
        }
      },
      // 设置统一表单数据
      setKFormBuildData() {

      },
      //保存表单并启动
      startUp(str) {
        const _this = this;
        this.startUpLoading = true
        this.$refs.KFB.getData()
        .then(res => {
          let obj = {
              ..._this.model,
              // act_status: 1,
              ...res
          }
          //在这里调用保存表单的接口
          _this.submitForm(obj, str);
        }).catch(err => {
          _this.startUpLoading = false
          _this.$message.error('请将表单数据填写完整')
        })
      },
    //   先保存一次，拿编号
      // firstSetForm(resouce, str) {
      //   let jsonObj = this.formJson;
      //   let isCustUrl = !!jsonObj.addUrl;
      //   if(isCustUrl) {
      //     //自定义表单保存
      //     custFormSave({
      //       url:jsonObj.addUrl,
      //       data:resouce
      //     }).then(res => {
      //       if(res.success) {
      //         resouce.id = res.result;
      //         // 再获取编码进行保存
      //         this.getOrderNum(resouce, str)
      //       }else {
      //         this.$message.error('表单保存失败')
      //       }
      //     })
      //   }else {
      //       if (resouce.id) {
      //           setFormData({
      //               query: jsonObj.cgformHeadId,
      //               obj: resouce,
      //               method: 'put'
      //           }).then(res=>{
      //               //在这里调用启动的接口
      //               if(res.success) {
      //                   // 再获取编码进行保存
      //                   this.getOrderNum(resouce, str)
      //               } else {
      //                   this.$message.error('表单保存失败')
      //               }
      //           })
      //       } else {
      //           //统一保存表单
      //           formSave({
      //               query:`tabletype=1&processName=${this.reQuery.processName}&definitionId=${this.reQuery.definitionId}`,
      //               code:jsonObj.cgformHeadId,
      //               data:resouce
      //           }).then(res => {
      //               //在这里调用启动的接口
      //               if(res.success) {
      //                   resouce.id = res.result;
      //                   // 再获取编码进行保存
      //                   this.getOrderNum(resouce, str, jsonObj)
      //               } else {
      //                   this.$message.error('表单保存失败')
      //               }
      //           }).catch(err => {
      //               this.$message.error('表单保存失败')
      //           })
      //       }
      //   }
      // },
      //保存表单
      submitForm(resouce, str) {
        // let url = this.formJson.addUrl
        //         ? this.formJson.addUrl
        //         : `/activiti-server/activiti/definition/save/${params.code}?${params.query}`
        
        let jsonObj = this.formJson;
        let isCustUrl = !!jsonObj.addUrl;
        // return;
        if(isCustUrl) {
          //自定义表单保存
          custFormSave({
            url: jsonObj.addUrl,
            data: {
              ...resouce,
              processName: this.reQuery.processName,
              definitionId: this.reQuery.definitionId
            }
          }).then(res => {
            if(res.success) {
              if(str=='start') {
                this.getProc(res.result).then(resp => {
                  // if (resp.success) {
                    this.custQdHandler(resp);
                  // }
                })
              } else {
                this.startUpLoading = false
                this.$message.success('表单保存成功')
                this.closeCurrent();
                this.$router.push({
                  path: '/newActiviti/task/apply'
                });
              }
            } else {
              this.startUpLoading = false
              this.$message.error('表单保存失败')
            }
          })
        } else {
            if (resouce.id) {
                setFormData({
                    query: jsonObj.cgformHeadId,
                    obj: resouce,
                    method: 'put'
                }).then(res=>{
                    //在这里调用启动的接口
                    if(res.success) {
                        //启动流程
                        if(str=='start') {
                          this.qdHandler({
                            businessKey:resouce.id,
                            code:jsonObj.cgformHeadId,
                            json:resouce
                          });
                        }else {
                          this.startUpLoading = false
                          this.$message.success('表单保存成功')
                          this.closeCurrent();
                          this.$router.push({
                            path: '/newActiviti/task/apply'
                          });
                        }
                    } else {
                      this.startUpLoading = false
                      this.$message.error('表单保存失败')
                    }
                }).catch(err=>{
                  this.startUpLoading = false
                })
            } else {
                //统一保存表单
                formSave({
                    query:`tabletype=1&processName=${this.reQuery.processName}&definitionId=${this.reQuery.definitionId}`,
                    code:jsonObj.cgformHeadId,
                    data:resouce
                }).then(res => {
                    //在这里调用启动的接口
                    if(res.success) {
                        //启动流程
                        if(str=='start') {
                            this.qdHandler({
                            businessKey:res.result,
                            code:jsonObj.cgformHeadId,
                            json:resouce
                            });
                        }else {
                          this.startUpLoading = false
                          this.$message.success('表单保存成功')
                          this.closeCurrent();
                          this.$router.push({
                            path: '/newActiviti/task/apply'
                          });
                        }
                    } else {
                      this.startUpLoading = false
                      this.$message.error('表单保存失败')
                    }
                }).catch(err => {
                  this.startUpLoading = false
                  this.$message.error('表单保存失败')
                })
            }
        }
      },
    //   取表单编码
      // getOrderNum(resouce, str, jsonObj) {
      //   let fieldList = this.reFormData.list;
      //   // 用于获取表单编码，但是目前只获取一个。没有做两个，所以在设计表单的时候只设置一个code
      //   let fieldDefaultValue = '';
      //   let key = '';
      //   filterField(fieldList);
      //   function filterField(arr) {
      //       if (!arr || !arr.length) return;
      //       for (let index = 0; index < arr.length; index++) {
      //           const element = arr[index];
      //           if (element.type == "grid" || element.type == 'table' || element.type == 'tabs' || element.type == 'card' || element.type == 'batch') {
      //               if (element.columns && element.columns.length) {
      //                   element.columns.forEach(item => {
      //                       filterField(item.list)
      //                   });
      //               }
      //           } else {
      //               if (element.options.fieldDefaultValue) {
      //                   fieldDefaultValue = element.options.fieldDefaultValue.replace('${', '').replace('}', '');
      //                   key = element.model;
      //                   return;
      //               }
      //           }
                
      //       }
      //   }
      //   // 取号
      //   // 有编码code  同时  表单数据里面没有编码
      //   if (fieldDefaultValue && !resouce[key]) {
      //       putAction('/sys/fillRule/executeRuleByCode/' + fieldDefaultValue, resouce).then(res => {
      //           // 执行成功，获取返回的值，并赋到页面上
      //           if (res.success) {
      //               resouce[key] = res.result;
      //               this.submitForm(resouce, str)
      //           } else {
      //               this.$message.warning(res.message);
      //               this.submitForm(resouce, str)
      //           }
      //       })
      //   } else {
      //       // this.submitForm(resouce, str)
      //       if (str == 'start') {
      //         this.qdHandler({
      //           businessKey:resouce.id,
      //           code:jsonObj.cgformHeadId,
      //           json:resouce
      //         });
      //       } else {
      //         this.$message.success('表单保存成功')
      //       }
      //   }
      // },
      //启动-----统一
      qdHandler(obj) {
        qdApply({
          username:this.userInfo.username,
          definitionId:this.reQuery.definitionId,
          id:obj.businessKey,
          key:obj.businessKey,
          code:obj.code,
          json:obj.json,
          variables:null,
          category:this.reQuery.category
        }).then(res => {
          this.startUpLoading = false
          if(res.success) {
            this.$message.success('任务启动成功')
            this.closeCurrent();
            this.$router.push({
              path: '/newActiviti/task/apply'
            });
          }else {
            this.$message.error('任务启动失败')
          }
        }).catch(err => {
          this.startUpLoading = false
          this.$message.error('任务启动失败')
        })
      },
      
      //启动----自定义
      custQdHandler(resp) {
        if(Object.keys(resp).length == 0) {
          this.$message.warning('中间表未读取成功,找管理员')
          return
        }
        let variables = resp.variables ? JSON.parse(resp.variables) : {type:''};
        qdApply({
          username:this.userInfo.username,
          definitionId:this.reQuery.definitionId,
          category:this.reQuery.category,
          id:resp.businessKey, //businessKey
          variables:variables
        }).then(res => {
          if(res.success) {
            this.$message.success('任务启动成功');
            this.closeCurrent();
            this.$router.push({
              path: '/newActiviti/task/apply'
            });
          }else {
            this.$message.error('任务启动失败')
          }
        }).catch(err => {
          this.$message.error('任务启动失败')
        })
      },
      //获取自定义按钮
      initHandlerBtn() {
        let param = {
          instanceId:this.reQuery.processInstanceId,
          definitionId:this.reQuery.definitionId
        };
        getHandlerBtn(param)
        .then(res => {
          if(res.success) {
            this.definiteBtn = res.result;
          }else {
            this.$message.error(res.message);
          }
        })
      },
      //自定义按钮审批
      definiteHandler(item) {
        let flag = item.value == 'true';
        this.compHandler(flag);
      },
      // 办理--处理
      compHandler(flag) {
        this.confirmLoading = true;
        // 当表单不能修改时，走正常审核提交接口
        if (this.disabled) {
            this.setCompHandler(flag)
        } else {
            // 当表单可以修改时，需要先保存表单再走审核接口
            this.$refs.KFB.getData()
            .then(res => {
                //在这里调用保存表单的接口
                this.putFormData(res, flag);
            }).catch(err => {
                this.$message.error('请将表单数据填写完整')
            })
            
        }
      },
      putFormData(res, flag) {
        setFormData({
            query: this.formJson.cgformHeadId,
            obj: {
                ...this.model,
                ...res
            },
            method: 'put'
        }).then(res=>{
            if (res.success) {
                this.setCompHandler(flag);
            } else {
                this.$message.error('表单数据提交失败-审核失败');
            }
        })
      },
      setCompHandler(flag) {
        this.dealValue =  this.dealValue 
                        ? this.dealValue 
                        : flag ? '同意' : '不同意';
        completeHandler({
          taskId:this.reQuery.taskId,
          instanceId:this.reQuery.processInstanceId,
          variables:{
            comment: this.dealValue,
            pass:flag
          }
        }).then(res => {
          if(res.success) {
            this.$message.success('审核成功');
            this.confirmLoading = false;
            this.closeCurrent();
            this.$router.push({
              path: '/newActiviti/task/myDone'
            });
          }else {
            this.$message.error('审核失败');
          }
        })
      },
      //获取流程数据---编辑/查看/启动
      getProc(activitiId) {
        return getProcessByActi(activitiId).then(res => {
          if(res.success) {
            return res.result;
          }else {
            this.$message.warning(res.message);
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .form_model {
    // height: calc(100vh - 110px);
    background-color: #ffffff;
    display: flex;
    .lc_sj {
      flex:3;
      margin-bottom: 50px;
    }
    .lc_cz {
      // flex:1;
      // padding-left: 20px;
      // border-left: 1px solid #ccc;
      position: fixed;
      width: 100%;
      bottom: 0;
      right: 0;
      height: 86px;
      line-height: 56px;
      box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
      background: #fff;
      border-top: 1px solid #e8e8e8;
      padding: 0 24px;
      z-index: 9;
    }
  }
</style>
