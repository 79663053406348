<template>
  <!-- <j-modal
    :title="'甘特图'"
    :visible="visible"
    :fullscreen="true"
    switchFullscreen
    @cancel="close"
    :footer="false"
    cancelText="关闭"> -->
    <div id="userLayout" :class="['user-layout-wrapper', device]">
    <div class="container">
      <div class="top">
        <div class="header">
          <a href="/">
            <img src="~@/assets/logo.png" class="logo" alt="logo">
            <span class="title">首辅设计-项目进度图</span>
          </a>
        </div>
        <div class="desc">
          简单、务实、高效、不立牌坊
        </div>
      </div>

      <a-spin :spinning="confirmLoading">
      <div class="container">
        <!-- <div class="right-container">
          <ul class="gantt-messages">
            <li class="gantt-message" v-for="(message, index) in messages" v-bind:key="index">{{message}}</li>
          </ul>
        </div> -->
        <a-form layout="inline">
          <a-row :gutter="24" >
            <a-col :xl="6" :lg="7" :md="8" :sm="24">
              <a-form-item label="年月日筛选">
                <a-radio-group v-model="date" @change="changeDate">
                  <a-radio-button value="day">日</a-radio-button>
                  <a-radio-button value="month">月</a-radio-button>
                  <a-radio-button value="year">年</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <!-- <a-col :xl="6" :lg="7" :md="8" :sm="24">
              <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
                <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
                <a @click="handleToggleSearch" style="margin-left: 8px">
                  {{ toggleSearchStatus ? '收起' : '展开' }}
                  <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col> -->
          </a-row>
        </a-form>
        <gantt 
          v-if="tasks.data.length"
          ref="ganttMaodal"
          class="left-container" 
          :tasks="tasks"
          @task-updated="logTaskUpdate" 
          @link-updated="logLinkUpdate"></gantt>
        
      </div>
    </a-spin>

      <div class="footer">
        <div class="copyright">
          Copyright &copy; 2021 首辅工程设计有限公司 <a href="https://beian.miit.gov.cn/">蜀ICP备2021009126号</a>
        </div>
      </div>
    </div>
  </div>
    
  <!-- </j-modal> -->
</template>

<script>
  import { baseUrl } from "@/api/api.js"
  import { GetAtion, HttpAction } from "@/utils/http"
  import Gantt from '@/views/hrProjectManage/hrProject/ganttApp/Gantt.vue';

  export default {
    name: "GanttModal",
    components: {Gantt},
    data () {
      return {
        title:"操作",
        visible: false,
        model: {
          hrProjectMemberList: []
        },
        // 选择用户查询条件配置
        selectUserQueryConfig: [
          {key: 'phone', label: '电话'},
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        validatorRules:{},
        url: {
          add: baseUrl + "/hr-server/hrProject/add",
          edit: baseUrl + "/hr-server/hrProject/edit",
          canttData: baseUrl + "/hr-server/hrProject/canttData",
        },
        messages: [],
        date: 'month',
        tasks: {
          data: [],
          link: []
        }
      }
    },
    created () {
    },
    mounted() {
      this.edit(this.$route.query)
      document.body.classList.add('userLayout')
    },
    beforeDestroy () {
      document.body.classList.remove('userLayout')
    },
    computed: {
    },
    methods: {
      add () {
        //初始化默认值
        this.edit({});
      },
      edit (record) {
        this.model = Object.assign({}, record);
        this.visible = true;
        this.confirmLoading = true;
        this.tasks.data = []
        GetAtion(this.url.canttData, { projectId: record.id }).then(res => {
          if (res.success) {
            this.init(res.result)
          } else {
            this.confirmLoading = false;
          }
          
        }).catch(() => {
          this.confirmLoading = false;
        })
        
      },
      init(res) {
        const { id, name: text, planStartTime, planEndTime, proportion: progress, status, chargeName } = res.project
        let f = { 
          id, 
          text, 
          start_date: new Date(planStartTime), 
          end_date: new Date(planEndTime), 
          open: true, 
          toolTipsTxt: text, 
          progress,
          type: '',
          chargeName,
          status
        }
        let tasksData = [f]
        if (res.taskList) {
          let taskList = this.anewArr(res.taskList, f)
          tasksData = tasksData.concat(this.filterArr(taskList))
        }
        
        this.tasks.data = tasksData.map(function(current, ind, arry) {
          var newObj = {}
          if (current.type) { //存在type字段 说明非一级菜单，判断阶段的具体类型 设置不同颜色
            if (current.type == 1) { //冒烟
              newObj = Object.assign({}, current, { 'color': '#62ddd4' })
            } else if (current.type == 2) { //单元
              newObj = Object.assign({}, current, { 'color': '#fec0dc' })
            } else if (current.type == 3) { //回归
              newObj = Object.assign({}, current, { 'color': '#fcca02' })
            } else if (current.type == 4){ // 超时
              newObj = Object.assign({}, current, { 'color': '#d81e14' })
            } else if (current.type == 5){
              newObj = Object.assign({}, current, { 'color': '#d1a6ff' })
            }
          } else { //一级菜单是蓝色的
            newObj = Object.assign({}, current, { 'color': '#5692f0' })
          }

          return newObj
        })

        setTimeout(() => {
          this.confirmLoading = false;
          this.$refs.ganttMaodal.init()
        }, 800)
      },
      filterArr(arr) {
        let newArr = []
        arr.forEach(item=> {
          if (item.children && item.children.length) {
            let children = item.children
            delete item.children
            newArr.push(item)
            newArr = newArr.concat(this.filterArr(children))
          } else {
            newArr.push(item)
          }
          
        })
        return newArr
      },
      anewArr(arr, f) {
        return arr.map(item => {
          if (item.children && item.children.length) {
            item.children = this.anewArr(item.children, f)
          }
          const obj = {
            text: item.name,
            id: item.id,
            parent: item.pid || f.id,
            type: item.status,
            open: true,
            progress: item.proportion,
            duration: item.duration,
            children: item.children,
            start_date: new Date(item.planStartTime), 
            chargeName: item.chargeName,
            status: item.status,
          }
          return obj
        });
      },
      close () {
        this.$emit('close');  
        this.visible = false;
        this.tasks.data = [];
      },
      addMessage (message) {
        console.log('message-----------', message);
        this.messages.unshift(message)
        if (this.messages.length > 40) {
          this.messages.pop()
        }
      },
  
      logTaskUpdate (id, mode, task) {
        let text = (task && task.text ? ` (${task.text})`: '')
        let message = `Task ${mode}: ${id} ${text}`
        this.addMessage(message)
      },
  
      logLinkUpdate (id, mode, link) {
        let message = `Link ${mode}: ${id}`
        if (link) {
          message += ` ( source: ${link.source}, target: ${link.target} )`
        }
        this.addMessage(message)
      },
      changeDate(value) {
        this.$refs.ganttMaodal.changeDate(value.target.value)
      }
    }
  }
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
    height: 100%;
    &.mobile {
      .container {
        .main {
          width: 98%;
        }
      }
    }

    .container {
      width: 100%;
      min-height: 100%;
      background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
      background-size: 100%;
      padding: 20px 0 144px;
      position: relative;

      a {
        text-decoration: none;
      }

      .top {
        text-align: center;

        .header {
          height: 44px;
          line-height: 44px;

          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }

          .logo {
            height: 44px;
            vertical-align: top;
            margin-right: 16px;
            border-style: none;
          }

          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, .85);
            font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }

      .main {
        min-width: 260px;
        width: 96%;
        margin: 0 auto;
      }

      .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 16px;
        margin: 48px 0 24px;
        text-align: center;

        .links {
          margin-bottom: 8px;
          font-size: 14px;
          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }
  }
.select_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.container {
  height: 100%;
  width: 100%;
}
.left-container {
  overflow-y: auto;
  position: relative;
  height: 100%;
}
.right-container {
  border-right: 1px solid #cecece;
  float: right;
  height: 100%;
  width: 340px;
  box-shadow: 0 0 5px 2px #aaa;
  position: relative;
  z-index:2;
}
.gantt-messages {
  list-style-type: none;
  height: 50%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 5px;
}
.gantt-messages > .gantt-message {
  background-color: #f4f4f4;
  box-shadow:inset 5px 0 #d69000;
  font-family: Geneva, Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 5px 0;
  padding: 8px 0 8px 10px;
}
</style>