<template>
    <div class="home">
        <div class="logo">
            <img :src="require('@/assets/logo.png')" alt="首辅" srcset="" 
                style="width:90px;height:40px;margin-right:20px;">
            <div class="home_title">
                <!-- <h3>首辅设计</h3> -->
                <p>简单、务实、高效、不立牌坊</p>
            </div>
        </div>
        <div class="home_main">
            <van-field
                clearable
                v-model="username"
                placeholder="请输入您的用户账号">
            </van-field>
            <van-button class="btn"
                size="large" round  type="info" 
                @click="goNext">
                查看内容
            </van-button>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { baseUrl } from "@/api/api.js"
import {
    Field,
    Button,
    Toast
} from 'vant';
import {
    secret,
    checkLogin,
    // getProcessByActi,
    // getTaskId
} from '@/api/api';
import { PostForm } from "@/utils/http"
import {getQueryString} from '@/utils/util';
import {mapMutations} from 'vuex';
export default {
    name: 'Home',
    components: {
        [Field.name]:Field,
        [Button.name]:Button,
    },
    data() {
        return {
            username:''
        }
    },
    mounted() {
        this.init();
    },
    methods:{
        //初始化
        init() {
            let id = getQueryString('id');
            // console.log(id);
            secret({
                id:id
            }).then(res => {
                if(res.success) {
                    this.Set_operateParams(res.result);
                    // this.getProc(res.result.activitiId);
                }else {
                    Toast.fail(res.message);
                }
            })
        },
        
        // //获取流程数据---编辑/查看/启动
        // getProc(activitiId) {
        //     getProcessByActi(activitiId).then(res => {
        //         if(res.success) {
        //             this.Set_processData(res.result);
        //             this.initTaskId(res.result.instanceId);
        //         }else {
        //             Toast.fail(res.message);
        //         }
        //     })
        // },
        // //获取taskId
        // initTaskId(instanceId) {
        //     getTaskId(instanceId).then(res => {
        //         if(res.success) {
        //             let resp = res.result?res.result:'';
        //             this.SET_taskId(resp);
        //         }else {
        //             Toast.fail(res.message);
        //         }
        //     })
        // },
        //查看
        goNext() {
            // console.log(this.username);
            let id = getQueryString('id');
            PostForm(baseUrl + 'hr-server/h5/filter/check', {
                pwd:this.username,
                id:id
            }).then(res => {
                if(res.success) {
                    this.Set_token(res.result);
                    this.$router.push('/hrProjectManage/hrTaskActiviti');
                }else {
                    Toast.fail('请输入正确的用户名');
                    this.username = '';
                }
            })
        },
        ...mapMutations(['Set_token','Set_operateParams',
        // 'Set_processData','SET_taskId'
        ])
    }
}
</script>
<style lang="less" scoped>
.home {
    height: 100vh;
    overflow: hidden;
    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25%;
        text-align: center;
        .home_title p {font-size: 12px;color:#ccc;}
    }
    .home_main {
        padding: 30px 20px;
        margin-top: 30px;
        background-color: aliceblue;
        .btn {margin-top: 40px;}
        /deep/.van-button--large {height: 40px;}
    }
}
</style>
