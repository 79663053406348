<template>
  <a-row class="j-select-biz-component-box" type="flex" :gutter="12">
    <a-col class="left" :class="{'full': !buttons}">
      <slot name="left">
        <a-select
            mode="multiple"
            v-model="selectValue"
            :disabled="disabled"
            :placeholder="placeholder"
            :filter-option="true"
            :not-found-content="fetching ? undefined : null"
            @search="fetchUser"
            @change="handleChange"
        >
            <a-icon slot="suffixIcon" type="search" @click="suffixIcon" />
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in selectOptions" :key="d.id" :value="d.id" :disabled="(d.status!='0' && jSelectMultiType == 'equipment') ? true: false">
                {{ d.name || d.title }}
            </a-select-option>
        </a-select>
      </slot>
    </a-col>

    <a-col v-if="buttons" class="right">
      <a-button type="primary" icon="search" :disabled="disabled" @click="visible=true">{{selectButtonText}}</a-button>
    </a-col>

    <h-select-biz-component-modal
      v-model="selectValue"
      :visible.sync="visible"
      v-bind="modalProps"
      @options="handleOptions"
    />
  </a-row>
</template>

<script>
  import HSelectBizComponentModal from './HSelectBizComponentModal'
  import {preIcon} from '@/utils/util'
  import { GetAtion, HttpAction } from "@/utils/http"
  import debounce from 'lodash/debounce';

  export default {
    name: 'JSelectBizComponent',
    components: { HSelectBizComponentModal },
    props: {
      value: {
        type: String,
        default: ''
      },
      valueName: {
        type: String,
        default: ''
      },
      /** 是否返回 id，默认 false，返回 code */
      returnId: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: '请选择'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      // 是否支持多选，默认 true
      multiple: {
        type: Boolean,
        default: true
      },
      // 是否显示按钮，默认 true
      buttons: {
        type: Boolean,
        default: true
      },
      // 显示的 Key
      displayKey: {
        type: String,
        default: null
      },
      // 返回的 key
      returnKeys: {
        type: Array,
        default: () => ['id', 'id']
      },
      // 选择按钮文字
      selectButtonText: {
        type: String,
        default: '选择'
      },
      //固定查询条件
      queryParamString: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 300);
      return {
        selectValue: [],
        selectOptions: [],
        dataSourceMap: {},
        fetching: false,
        list: [],
        visible: false,
        selectOpen: false,
      }
    },
    created() {
        
    },
    computed: {
        jSelectMultiType() {
            if (this.$attrs.jSelectMultiType) {
                return this.$attrs.jSelectMultiType
            }
            return ''
        },
      valueKey() {
        return this.returnId ? this.returnKeys[0] : this.returnKeys[1]
      },
      modalProps() {
        return Object.assign({
          valueKey: this.valueKey,
          multiple: this.multiple,
          returnKeys: this.returnKeys,
          displayKey: this.displayKey || this.valueKey,
          queryParamString: this.queryParamString
        }, this.$attrs)
      },
    },
    watch: {
      value: {
        // immediate: true,
        handler(val) {
          if (val) {
            this.selectValue = val.split(',')
            if (this.valueName) {
                let code = false
                for (let index = 0; index < this.selectOptions.length; index++) {
                    const element = this.selectOptions[index];
                    if (this.selectValue[0] == element.id) {
                        code = true
                        break;
                    }
                }
                if (!code) { // 没有
                    this.selectOptions.push({ name: this.valueName, id: this.selectValue[0] })
                }
            } else {
                this.getList({ [this.valueKey]: val })
            }
          } else {
            this.selectValue = []
          }
        }
      },
      selectValue: {
        deep: true,
        handler(val) {
          let rows = val.map(key => this.dataSourceMap[key])
          let data = val.join(',')
          if (data !== this.value) {
            this.$emit('select', rows)
            this.$emit('input', data)
            this.$emit('change', data)
          }
        }
      }
    },
    methods: {
        suffixIcon() {

        },
        fetchUser(value) {
            console.log('fetching user', value);
            this.lastFetchId += 1;
            const fetchId = this.lastFetchId;
            this.selectOptions = [];
            this.fetching = true;
            let obj = {
                name: preIcon(value)
            }
            GetAtion(this.$attrs.valueUrl || this.$attrs.listUrl, obj).then((res) => {
                if (res.success) {
                    if (fetchId !== this.lastFetchId) {
                        // for fetch callback order
                        return;
                    }
                    let dataSource = res.result
                    if (!(dataSource instanceof Array)) {
                    dataSource = res.result.records
                    }
                    this.selectOptions = dataSource
                }
            })
        },
        handleChange(value) {
            let arr = []
            if (!this.multiple && value.length) {
                arr.push(value[value.length-1])
            } else {
                arr = value
            }
            this.selectValue = arr;
            this.fetching = false
        },
        getList(parmas) {
            GetAtion(this.$attrs.valueUrl || this.$attrs.listUrl, parmas).then((res) => {
                if (res.success) {
                    let dataSource = res.result
                    if (!(dataSource instanceof Array)) {
                    dataSource = res.result.records
                    }
                    this.selectOptions = dataSource
                }
            })
        },
        
      handleOptions(options, dataSourceMap) {
        this.selectOptions = options.map(item=>{
            item.id = item.value
            item.name = item.label
            return item
        })
        this.dataSourceMap = dataSourceMap
      },
      handleDropdownVisibleChange() {
        // 解决antdv自己的bug —— open 设置为 false 了，点击后还是添加了 open 样式，导致点击事件失效
        this.selectOpen = true
        this.$nextTick(() => {
          this.selectOpen = false
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .j-select-biz-component-box {

    @width: 82px;

    .left {
      width: calc(100% - @width - 8px);
    }

    .right {
      width: @width;
    }

    .full {
      width: 100%;
    }

    // /deep/ .ant-select-search__field {
    //   display: none !important;
    // }
  }
</style>