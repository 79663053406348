<template>
  <a-modal
    centered
    :title="name + '选择'"
    :width="width"
    :visible="visible"
    switchFullscreen
    @ok="handleOk"
    @cancel="close"
    cancelText="关闭">

    <a-row :gutter="18">
      <a-col :span="16">
        <!-- 查询区域 -->
        <a-form layout="inline" class="j-inline-form">
          <a-button type="primary" v-if="routeData.routeName" @click="handleAdd" style="margin-right: 8px">新增</a-button>
          <!-- 固定条件 -->
          <a-form-item :label="(queryParamText||name)">
            <a-input v-model="queryParamValue" :placeholder="'请输入' + (queryParamText||name)" @pressEnter="searchQuery"/>
          </a-form-item>
          <!-- 动态生成的查询条件 -->
          <j-select-biz-query-item v-if="queryConfig.length>0" v-show="showMoreQueryItems" :queryParam="queryParam" :queryConfig="queryConfig" @pressEnter="searchQuery"/>
          <!-- 按钮 -->
          <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
          <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
          <a v-if="queryConfig.length>0" @click="showMoreQueryItems=!showMoreQueryItems" style="margin-left: 8px">
            {{ showMoreQueryItems ? '收起' : '展开' }}
            <a-icon :type="showMoreQueryItems ? 'up' : 'down'"/>
          </a>
        </a-form>

        <a-table
          size="middle"
          bordered
          :rowKey="rowKey"
          :columns="innerColumns"
          :dataSource="dataSource"
          :pagination="ipagination"
          :loading="loading"
          :scroll="{ y: 240 }"
          :rowSelection="{selectedRowKeys, onChange: onSelectChange, type: multiple ? 'checkbox':'radio',getCheckboxProps: getCheckboxProps}"
          :customRow="customRowFn"
          @change="handleTableChange">
        </a-table>

      </a-col>
      <a-col :span="8">
        <a-card :title="'已选' + name" :bordered="false" :head-style="{padding:0}" :body-style="{padding:0}">

          <a-table size="middle" :rowKey="rowKey" bordered v-bind="selectedTable">
              <span slot="action" slot-scope="text, record, index">
                <a @click="handleDeleteSelected(record, index)">删除</a>
              </span>
          </a-table>

        </a-card>
      </a-col>
    </a-row>
    <a-modal
      :title="lcModa.title"
      width="80%"
      :visible="lcModa.visible"
      :fullscreen="lcModa.fullscreen"
      switchFullscreen
      :maskClosable="false"
      :footer="null"
      @cancel="handleCancel">
        <component
          :disabled="lcModa.disabled"
          v-if="lcModa.visible"
          :is="lcModa.formComponent"
          @ok="modalFormOk"
          @close="lcModa.visible=false,lcModa.disabled = false"
        ></component>
    </a-modal>
  </a-modal>
</template>

<script>
  import {preIcon} from '@/utils/util'
  import { GetAtion, HttpAction } from "@/utils/http"
  import Ellipsis from '@/components/Ellipsis'
  import { JeecgListMixin } from './JeecgListMixin'
  import { cloneObject, pushIfNotExist } from '@/utils/util'
  import JSelectBizQueryItem from '@/components/JSelectBizComponent/JSelectBizQueryItem'

  export default {
    name: 'HSelectBizComponentModal',
    mixins: [JeecgListMixin],
    components: {Ellipsis, JSelectBizQueryItem},
    props: {
      value: {
        type: Array,
        default: () => []
      },
      visible: {
        type: Boolean,
        default: false
      },
      valueKey: {
        type: String,
        required: true
      },
      multiple: {
        type: Boolean,
        default: true
      },
      width: {
        type: Number,
        default: 900
      },

      name: {
        type: String,
        default: ''
      },
      listUrl: {
        type: String,
        required: true,
        default: ''
      },
      // 根据 value 获取显示文本的地址，例如存的是 username，可以通过该地址获取到 realname
      valueUrl: {
        type: String,
        default: ''
      },
      displayKey: {
        type: String,
        default: null
      },
      columns: {
        type: Array,
        required: true,
        default: () => []
      },
      //固定查询条件
      queryParamString: {
        type: Object,
        default: () => ({})
      },
      routeData: {
        type: Object,
        default: () => ({})
      },
      // 查询条件Code
      queryParamCode: {
        type: String,
        default: null
      },
      // 查询条件文字
      queryParamText: {
        type: String,
        default: null
      },
      // 查询配置
      queryConfig: {
        type: Array,
        default: () => []
      },
      rowKey: {
        type: String,
        default: 'id'
      },
      // 过长裁剪长度，设置为 -1 代表不裁剪
      ellipsisLength: {
        type: Number,
        default: 12
      },
    },
    data() {
      return {
        lcModa: {
          title: '',
          disabled: false,
          visible: false,
          formComponent: null,
          isNew: false,
          fullscreen: false
        },
        queryParamValue: "",
        innerValue: [],
        // 已选择列表
        selectedTable: {
          pagination: false,
          scroll: { y: 240 },
          columns: [
            { title: '操作', dataIndex: 'action', align: 'center', width: 60, scopedSlots: { customRender: 'action' }, }
          ],
          dataSource: [],
        },
        renderEllipsis: (value) => (<ellipsis length={this.ellipsisLength}>{value}</ellipsis>),
        url: { list: this.listUrl },
        /* 分页参数 */
        ipagination: {
          current: 1,
          pageSize: 5,
          pageSizeOptions: ['5', '10', '20', '30'],
          showTotal: (total, range) => {
            return range[0] + '-' + range[1] + ' 共' + total + '条'
          },
          showQuickJumper: true,
          showSizeChanger: true,
          total: 0
        },
        options: [],
        dataSourceMap: {},
        showMoreQueryItems: false,
      }
    },
    created() {
        console.log('jSelectMultiType', this.$attrs.jSelectMultiType);
    },
    computed: {
        jSelectMultiType() {
            if (this.$attrs.jSelectMultiType) {
                return this.$attrs.jSelectMultiType
            }
            return ''
        },
      // 表头
      innerColumns() {
        let columns = cloneObject(this.columns)
        if (this.columns.length) {
          this.selectedTable.columns.unshift({
            ...this.columns[0]
          })
        }
        columns.forEach(column => {
          // 给所有的列加上过长裁剪
          if (this.ellipsisLength !== -1) {
            column.customRender = (text) => this.renderEllipsis(text)
          }
        })
        return columns
      },
    },
    watch: {
      value: {
        deep: true,
        // immediate: true,
        handler(val) {
          this.innerValue = cloneObject(val)
          this.selectedRowKeys = []
          this.valueWatchHandler(val)
        //   this.queryOptionsByValue(val)
        //   console.log('====================================');
        //   console.log('查看查询了几次');
        //   console.log('====================================');
        }
      },
      visible(val) {
          if (!this.disableMixinCreated && val) {
            console.log(' -- mixin created --22222222222 ', this.queryParamString)
            // 拿到固定的筛选条件
            for (const key in this.queryParamString) {
                this.queryParam[key] = this.queryParamString[key]
            }
            this.loadData();
            //初始化字典配置 在自己页面定义
            this.initDictConfig();
          }
      },
      dataSource: {
        deep: true,
        handler(val) {
          this.emitOptions(val)
          this.valueWatchHandler(this.innerValue)
        }
      },
      selectedRowKeys: {
        immediate: true,
        deep: true,
        handler(val) {
          //update--begin--autor:scott-----date:20200927------for：选取职务名称出现全选 #1753-----
          if(this.innerValue){
            this.innerValue.length=0;
          }
          //update--end--autor:scott-----date:20200927------for：选取职务名称出现全选 #1753-----
          this.selectedTable.dataSource = val.map(key => {
            for (let data of this.dataSource) {
              if (data[this.rowKey] === key) {
                pushIfNotExist(this.innerValue, data[this.valueKey])
                return data
              }
            }
            for (let data of this.selectedTable.dataSource) {
              if (data[this.rowKey] === key) {
                pushIfNotExist(this.innerValue, data[this.valueKey])
                return data
              }
            }
            console.warn('未找到选择的行信息，key：' + key)
            return {}
          })
        },
      }
    },

    methods: {
      handleAdd() {
        if (!this.routeData.routeName) {
          this.$message.warning('未配置表单，请联系开发人员！')
          return
        }
        
        this.lcModa.formComponent = this.getFormComponent(this.routeData.routeName).component
        this.lcModa.visible = true
        this.lcModa.fullscreen = this.routeData.fullscreen
      },
      // 关闭弹窗
      handleCancel(){
        this.lcModa.visible = false;
        // console.log('close-====',this.lcModa.disabled);
      },
        getCheckboxProps(record) {
            return {
                props: {
                    disabled: record.status != '0' && this.jSelectMultiType == 'equipment', // Column configuration not to be checked
                    name: record.name,
                },
            }
            
        },
        // 重置页面(临时)处理参数
        searchReset() {
            this.queryParamValue = ''
            this.queryObj = {};
            this.queryParam = {};
            this.loadData(1);
        },
        searchQuery(e) {
            // console.log('搜索', this.queryParam);
            this.queryParam[this.queryParamCode || this.valueKey] = preIcon(this.queryParamValue);
            this.loadData(1);
        },
      /** 关闭弹窗 */
      close() {
        this.$emit('update:visible', false)
      },

      valueWatchHandler(val) {
        val.forEach(item => {
          this.dataSource.concat(this.selectedTable.dataSource).forEach(data => {
            if (data[this.valueKey] === item) {
              pushIfNotExist(this.selectedRowKeys, data[this.rowKey])
            }
          })
        })
      },

      queryOptionsByValue(value) {
        if (!value || value.length === 0) {
          return
        }
        // 判断options是否存在value，如果已存在数据就不再请求后台了
        let notExist = false
        for (let val of value) {
          let find = false
          for (let option of this.options) {
            if (val === option.value) {
              find = true
              break
            }
          }
          if (!find) {
            notExist = true
            break
          }
        }
        if (!notExist) return
        GetAtion(this.valueUrl || this.listUrl, {
          // 这里最后加一个 , 的原因是因为无论如何都要使用 in 查询，防止后台进行了模糊匹配，导致查询结果不准确
          [this.valueKey]: value.join(',') + ',',
          pageNo: 1,
          pageSize: value.length
        }).then((res) => {
          if (res.success) {
            let dataSource = res.result
            if (!(dataSource instanceof Array)) {
              dataSource = res.result.records
            }
            this.emitOptions(dataSource, (data) => {
              pushIfNotExist(this.innerValue, data[this.valueKey])
              pushIfNotExist(this.selectedRowKeys, data[this.rowKey])
              pushIfNotExist(this.selectedTable.dataSource, data, this.rowKey)
            })
          }
        })
      },

      emitOptions(dataSource, callback) {
        dataSource.forEach(data => {
          let key = data[this.valueKey]
          this.dataSourceMap[key] = data
          pushIfNotExist(this.options, { label: data[this.displayKey || this.valueKey], value: key }, 'value')
          typeof callback === 'function' ? callback(data) : ''
        })
        this.$emit('options', this.options, this.dataSourceMap)
      },

      /** 完成选择 */
      handleOk() {
        // // let value = this.selectedTable.dataSource.map(data => data[this.valueKey]);
        // let temp = {};
        // this.returnKeys.forEach(val => {
        //   temp[val] = this.selectedTable.dataSource.map(data => data[val]);
        // })
        // let arr = []
        // let keys = Object.keys(temp)
        // temp[keys[0]].forEach((item,index) => {
        //   const elem = returnElem(index)
        //   arr.push(elem);
        // })
        // function returnElem(i) {
        //   let str = keys.map(elem=> temp[elem][i]).join('_')
        //   return  str
        // }
        // // console.log('===========完成选择,返回值',this.returnKeys,temp, arr);
        // // if(this.returnKeys.length > 2) {
        // this.$emit('input', arr)
        // // }else {
        // //   this.$emit('input', value)
        // // }
        
        // this.close()
        let value = this.selectedTable.dataSource.map(data => data[this.valueKey])
        this.$emit('input', value)
        this.close()
      },
      /** 删除已选择的 */
      handleDeleteSelected(record, index) {
        this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record[this.rowKey]), 1)
        //update--begin--autor:wangshuai-----date:20200722------for：JSelectBizComponent组件切换页数值问题------
        this.selectedTable.dataSource.splice(this.selectedTable.dataSource.indexOf(record), 1)
        this.innerValue.splice(this.innerValue.indexOf(record[this.valueKey]), 1)
        console.log("this.selectedRowKeys:",this.selectedRowKeys)
        console.log("this.selectedTable.dataSource:",this.selectedTable.dataSource)
        //update--begin--autor:wangshuai-----date:20200722------for：JSelectBizComponent组件切换页数值问题------
      },

      customRowFn(record) {
        return {
          on: {
            click: () => {
              // let key = record[this.rowKey]
              // if (!this.multiple) {
              //   this.selectedRowKeys = [key]
              //   this.selectedTable.dataSource = [record]
              // } else {
              //   let index = this.selectedRowKeys.indexOf(key)
              //   if (index === -1) {
              //     this.selectedRowKeys.push(key)
              //     this.selectedTable.dataSource.push(record)
              //   } else {
              //     this.handleDeleteSelected(record, index)
              //   }
              // }
            }
          }
        }
      },

    }
  }
</script>
<style lang="less" scoped>
  .full-form-item {
    display: flex;
    margin-right: 0;

    /deep/ .ant-form-item-control-wrapper {
      flex: 1 1;
      display: inline-block;
    }
  }

  .j-inline-form {
    /deep/ .ant-form-item {
      margin-bottom: 12px;
    }

    /deep/ .ant-form-item-label {
      line-height: 32px;
      width: auto;
    }

    /deep/ .ant-form-item-control {
      height: 32px;
      line-height: 32px;
    }
  }
</style>