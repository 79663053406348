<template>
  <div class="examine">
    <van-nav-bar
      :title="title"
      safe-area-inset-top
      fixed
      placeholder
      :z-index="999"
    />
    <van-tabs v-model="active" color="#1989fa">
      <van-tab title="项目详情">
        <van-form @submit="onSubmit">
          <van-cell-group :border="false">
            <van-cell title="项目名称" :value="loanData.name" />
            <van-cell title="负责人" :value="loanData.chargeName" />
            <van-field
              v-model="loanData.budgetAmount"
              input-align="right"
              type="number"
              name="budgetAmount"
              label="预算金额(元)"
              placeholder="预算金额(元)"
              :rules="[{ required: true, message: '请填预算金额(元)' }]"
            />
            <van-field
              readonly
              clickable
              input-align="right"
              name="planStartTime"
              :value="loanData.planStartTime"
              label="计划开始时间"
              placeholder="点击选择时间"
              @click="onShowPicker('planStartTime')"
              :rules="[{ required: true, message: '请选择计划开始时间' }]"
            />
            <van-field
              readonly
              clickable
              input-align="right"
              name="planEndTime"
              :value="loanData.planEndTime"
              label="计划结束时间"
              placeholder="点击选择时间"
              @click="onShowPicker('planEndTime')"
              :rules="[{ required: true, message: '请选择计划结束时间' }]"
            />
            <van-cell title="实际开始时间" :value="loanData.startTime || '未开始'" />
            <van-cell title="实际结束时间" :value="loanData.endTime || '未结束'" />
            <van-field
              readonly
              clickable
              input-align="right"
              name="accepterName"
              :value="loanData.accepterName"
              label="验收人"
              placeholder="点击选择验收人"
              @click="showList = true"
              :rules="[{ required: true, message: '请选择验收人' }]"
            />
            <van-field
              v-model="loanData.remark"
              input-align="left"
              name="remark"
              type="textarea"
              autosize
              rows="2"
              show-word-limit
              maxlength="1500"
              label="备注"
              placeholder="请输入备注"
            />
            <van-cell
              title="流程状态"
              :value="actStatu(loanData.actStatus)"
              value-class="act_status"
              title-class="act_status"
            />
            <van-cell v-if="ReportUrls.length">
              <template #title>
                <span class="custom-title">立项报告</span>
              </template>
              <template #default>
                <a style="display:block;margin:5px 0;" 
                  v-for="item in ReportUrls" :key="item.id"
                  :href="item.url" rel="noopener noreferrer">
                  {{item.fileName}}
                </a>
              </template>
            </van-cell>
            <van-cell v-if="ReviewUrls.length">
              <template #title>
                <span class="custom-title">立项评审</span>
              </template>
              <template #default>
                <a style="display:block;margin:5px 0;" 
                  v-for="item in ReviewUrls" :key="item.id"
                  :href="item.url" rel="noopener noreferrer">
                  {{item.fileName}}
                </a>
              </template>
            </van-cell>
          </van-cell-group>
          <div class="btn" v-if="taskId && !compHandlerLoading">
            <van-button type="danger" @click="back">不同意</van-button>
            <van-button type="info" @click="pass">同意</van-button>
            
          </div>
          <div class="btn">
            <van-loading v-if="compHandlerLoading" type="spinner" color="#1989fa" />
          </div>
          
        </van-form>
      </van-tab>

      <van-tab title="审批历史" class="exam_history">
        <van-steps direction="vertical" :active="0">
          <template v-for="item in historicFlow">
            <van-step :key="item.id" v-if="item.endTime">
              <h4>
                <span>{{ item.activityName }}</span>
                <span>{{ item.endTime }}</span>
              </h4>
              <p>[处理人]{{ item.assigneeName }}</p>
              <p v-if="item.comment">[审批意见]{{ item.comment }}</p>
            </van-step>
          </template>
        </van-steps>
      </van-tab>
    </van-tabs>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        title="选择计划开始时间"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- 人员列表 -->
    <van-popup
      v-model="showList"
      round
      position="bottom"
      style="height: 90%; padding-top: 4px;"
    >
      <van-search
        v-model="valueSearch"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-contact-card
          v-for="item in list" :key="item.userName"
          type="edit"
          :name="item.userName"
          :tel="item.phone"
          @click="onChange(item)"
        />
      </van-list>
    </van-popup>
    <!-- 审核弹窗 -->
    <van-dialog
      v-model="compObj.visible"
      :title="compObj.title"
      show-cancel-button
      confirmButtonColor="#1989fa"
      @cancel="(compObj.visible = false), (compObj.dealValue = '')"
      @confirm="compHandler"
    >
      <van-field
        label="审批意见"
        v-model="compObj.dealValue"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入意见"
      />
    </van-dialog>
  </div>
</template>

<script>
import { baseUrl,getFile } from "@/api/api.js"
import { getActStartProcess } from "@/api/activitiApi"
import { GetAtion, HttpAction } from "@/utils/http"
import {
  Loading,
  Search,
  NavBar,
  Button,
  Step,
  Steps,
  Field,
  Dialog,
  Cell,
  CellGroup,
  Toast,
  Tab,
  Tabs,
  Form,
  DatetimePicker,
  Popup,
  List,
  ContactCard,
} from "vant";
import {
  getHrProjectData,
  getRate,
  historyList,
  completeHandler,
  getProcessByActi,
  getTaskId,
} from "@/api/api";
import { mapMutations, mapGetters } from "vuex";

export default {
  components: {
    [Loading.name]: Loading,
    [Search.name]: Search,
    [ContactCard.name]: ContactCard,
    [List.name]: List,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Form.name]: Form,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      active: 0,
      title: "审核",
      loanData: {},
      historicFlow: [],
      url: {
        edit: baseUrl + "hr-server/hrProject/edit",
        getAllUserByOrgCode: baseUrl + "sys/user/getAllUserByOrgCode",
      },
      rate: "",
      //审批
      compObj: {
        visible: false,
        dealValue: "",
        type: "pass",
        title: "",
      },
      processData: {},
      taskId: "xx",
      username: '',
      password: '',
      showPicker: false,
      timeValueKey: '',
      showList: false,
      list: [
        {
          username: '何兴达',
          tel: '19828308761',
        },
        {
          username: '李攀',
          tel: '19828308761',
        },
      ],
      loading: false,
      finished: true,
      valueSearch: '',
      compHandlerLoading: false,
      //附件
      ReportUrls:[],
      ReviewUrls:[]
    };
  },
  computed: {
    // reStatue() {
    //     return this.taskId;
    //     // return ''
    // },
    ...mapGetters([
      "operateParams",
      // 'processData','taskId'
    ]),
  },
  created() {
    console.log("=======Create", this.operateParams);
    this.operateParams.activitiId &&
      this.getProc(this.operateParams.activitiId);
  },
  mounted() {
    //获取借款数据
    this.init();
  },
  watch: {
    taskId: {
      handler: function (val) {
        console.log("监听taskId状态", val);
        if (val) {
          this.title = "审核";
        } else {
          this.title = "查看";
        }
        this.initHistoryList();
      },
      immediate: true,
    },
  },
  methods: {
    onSubmit(values) {
      console.log('submit', values);
    },
    onChange(item) {
      this.loanData.accepterName = item.userName
      this.showList = false;
    },
    onLoad() {
    },
    onSearch() {
      this.getAllUserByOrgCode(this.valueSearch)
    },
    onConfirm(time) {
      let yy = new Date(time).getFullYear();
      let mm = new Date(time).getMonth()+1;
      let dd = new Date(time).getDate();
      this.loanData[this.timeValueKey] = yy + '-' + mm + '-' + dd;
      this.showPicker = false;
    },
    onShowPicker(key) {
      this.showPicker = true
      this.timeValueKey = key
    },
    //状态(整个流程)
    actStatu(val) {
      return val == 1 ? "进行中" : val == 2 ? "已结束" : "未启动";
    },
    //获取附件
    getFileList(ids) {
      return getFile({ids}).then(res => {
        if (res.success) {
          return res.result
        }
      })
    },
    //借款信息
    init() {
      let operateParams = this.operateParams;
      let tableId = operateParams.tableId;
      let uid = operateParams.userId;
      // console.log('operateParams',operateParams);
      getHrProjectData({
        id: tableId,
        uid: uid,
      }).then((res) => {
        // console.log(res);
        if (res.success) {
          let resp = res.result;
          this.loanData = res.result;
          this.getFileList(resp.projectReportId).then(res => {
            this.ReportUrls = res||[];
          })
          this.getFileList(resp.projectReviewId).then(res => {
            this.ReviewUrls = res||[];
          })
        }
      });
      this.getAllUserByOrgCode('')
    },
    getAllUserByOrgCode(userName) {
      GetAtion(this.url.getAllUserByOrgCode, {
        orgCode: 'aba4010b-81af-4e72-8e53-c4db274c8aab',
        userName: userName
      }).then(res=> {
        this.list = res.result
      }).finally(() => {

      })
    },
    //获取流程数据---编辑/查看/启动
    getProc(activitiId) {
      getProcessByActi(activitiId).then((res) => {
        if (res.success) {
          // this.Set_processData(res.result);
          this.processData = res.result;
          this.initTaskId(res.result.instanceId);
        } else {
          Toast.fail(res.message);
        }
      });
    },
    //获取taskId
    initTaskId(instanceId) {
      getTaskId(instanceId).then((res) => {
        if (res.success) {
          let resp = res.result ? res.result : "";
          // this.SET_taskId(resp);
          this.taskId = resp;
        } else {
          Toast.fail(res.message);
        }
      });
    },
    // 审批历史
    initHistoryList() {
      if (this.processData.instanceId) {
        historyList({
          processInstanceId: this.processData.instanceId,
        }).then((resp) => {
          if (resp.code === 200) {
            this.historicFlow = resp.rows.sort((a, b) => {
              return b.id - a.id;
            });
            console.log(this.historicFlow)
          }
        });
      }
    },
    //通过 passTask
    pass() {
      this.compObj = {
        visible: true,
        type: "pass",
        title: "审批通过",
      };
      console.log(this.compObj)
    },
    //驳回
    back() {
      this.compObj = {
        visible: true,
        type: "back",
        title: "审批不通过",
      };
    },
    //审批操作
    compHandler() {
      let flag = this.compObj.type == "pass";
      this.compHandlerLoading = true
      getActStartProcess('hr_project').then(result=>{
        this.loanData.hrProjectMemberList = []
        HttpAction(this.url.edit, {
          processName: result.result.name,
          procDefId: result.result.id,
          domain: this.loanData
        }, 'put').then(res=> {
          if (res.success) {
            completeHandler({
              taskId: this.taskId,
              instanceId: this.processData.instanceId,
              variables: {
                comment: this.compObj.dealValue,
                pass: flag,
              },
            }).then((res) => {
              if (res.success) {
                Toast.success("审核成功");
                this.compHandlerLoading = false
                this.taskId = "";
              } else {
                this.cancelCompHandlerLoading("审批失败,联系系统人员")
              }
            })
          } else {
            this.cancelCompHandlerLoading("修改表单失败,联系系统人员")
          }
        }).catch(err=> {
          this.cancelCompHandlerLoading("修改表单失败,联系系统人员")
        })
      }).catch(err=>{
        this.cancelCompHandlerLoading("获取流程信息失败,联系系统人员")
      })
    },
    cancelCompHandlerLoading(text) {
      Toast.fail(text);
      this.compHandlerLoading = false
    }
    // ...mapMutations(['SET_taskId'])
  },
};
</script>

<style lang="less" scoped>
@nav-bar-background-color: #1890ff;
/deep/.van-nav-bar {
  background-color: @nav-bar-background-color;
}
/deep/.van-nav-bar__title {
  color: #fff;
  font-size: 16px;
}
/deep/.van-step--vertical {
  padding-top: 0;
}
/deep/.van-step--vertical .van-step__circle-container {
  top: 10px;
}
/deep/.van-dialog__content {
  margin: 20px 10px;
}
.act_status {
  color: #ff976a;
}
.main {
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  box-sizing: border-box;
}
.btn {
  text-align: center;
  margin: 40px auto 20px;
  display: flex;
  justify-content: center;
  button {
    margin-right: 10px;
    height: 36px;
  }
  /deep/.van-button--normal {
    padding: 0 24px;
  }
}
</style>
