<template>
  <div>
    <div class="d">
      <div>
        <a-switch 
          :class="colorChecked ? 'switch' : 'switchF'" 
          :checked="colorChecked"
          default-checked 
          @change="onChange" />
        <span>深色模式</span>
      </div>
      <span>关系图</span>
      <div></div>
    </div>
    <div id="relationCharts"></div>
  </div>
  
</template>

<script>
import { 
  Toast,
} from "vant";
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { GraphChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import { TooltipComponent, LegendComponent } from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import { getechsData } from "@/api/api"
var myChart;
// 注册必须的组件
echarts.use([
  TooltipComponent,
  LegendComponent,
  GraphChart,
  CanvasRenderer,
  LabelLayout
]);
import { GetAtion } from "@/utils/http";

export default {
  data() {
    return {
      colorChecked: false,
      graph: {},
      option: {},
    };
  },
  mounted() {
    
    const query = this.$route.query
    window.onload = function () {
      if (!query.userId) {
        Toast.fail('请配备人员id')
        return
      }
      var chartDom = document.getElementById("relationCharts");
      myChart = echarts.init(chartDom);
      var option;
      const graph = {
        "nodes": [
          // {
          //   "id": "76",
          //   "name": "何兴达",
          //   "symbolSize": 60,
          //   // "x": 712.18353,
          //   // "y": 4.8131495,
          //   "value": '系统研发部',
          //   "category": 0
          // }
          // {
          //   name: "韦小宝",
          //   id: "1",
          //   symbolSize: 100,//节点大小
          //   // symbol:'image://http://g.hiphotos.baidu.com/zhidao/wh%3D450%2C600/sign=c94fde2ed300baa1ba794fbf72209524/00e93901213fb80e2d9e721534d12f2eb83894a0.jpg',//节点形状，'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'也可'image://url'设置节点图片
          //   "category": 0,
          //   "value": '系统研发部',
          // },
          // {
          //   name: "方怡",
          //   id: "2",
          //   symbolSize: 60,
          //   "category": 1
          // },
          // {
          //   name: "双儿",
          //   id: "3",
          //   symbolSize: 60,
          //   "category": 2
          // },
          // {
          //   name: "茅十八",
          //   id: "4",
          //   symbolSize: 60,
          //   "category": 3
          // },
          {
            name: "首辅设计公司A",
            id: "5",
            // value: '吴六奇',
            symbolSize: 60,
            "category": 6
          },
          {
            name: "吴六奇",
            id: "6",
            // value: '首辅设计公司B,首辅设计公司A',
            symbolSize: 60,
            "category": 0
          },
          {
            name: "首辅设计公司B",
            id: "7",
            // value: '吴六奇',
            symbolSize: 60,
            "category": 6
          },
        ],
        "links": [
          // {
          //   source: "1",
          //   target: "2",
          //   relation: {
          //     name: "老婆",
          //     id: "1",
          //   },
          // },
          // {
          //   source: "1",
          //   target: "3",
          //   relation: {
          //     name: "老婆",
          //     id: "1",
          //   },
          // },
          // {
          //   source: "1",
          //   target: "4",
          //   relation: {
          //     name: "兄弟",
          //     id: "1",
          //   },
          // },
          {
            source: "6", // 父节点
            target: "7", // 子节点
            relation: {
              name: "分管人",
              id: "1",
            },
          },
          {
            source: "6",
            target: "5",
            relation: {
              name: "分管人",
              id: "1",
            },
          },
          // {
          //   source: "3",
          //   target: "5",
          //   relation: {
          //     name: "义妹",
          //     id: "1",
          //   },
          // },
        ],
        "categories": [
          {
            "name": "一级"
          },
          {
            "name": "二级"
          },
          {
            "name": "三级"
          },
          {
            "name": "四级"
          },
          {
            "name": "五级"
          },
          {
            "name": "六级"
          },
          {
            "name": "七级"
          },
          {
            "name": "八级"
          },
          {
            "name": "九级"
          }
        ]
      }
      myChart.showLoading();
      console.log(query,'query-----')
      getechsData({
        // id: 'acde00a1-c700-47e6-8d7e-2a2a7259d38b',
        legalUserId:query.UserId,
        legalUserName:query.UserName,
        companyId:query.companyId,
        companyName:query.companyName
      }).then(res => {
        if (res.success) {
          graph.nodes = res.result.paramListVos.map(item=> {
            const obj = {
              ...item,
              name: item.company || item.name,
              id: item.companyId || item.nameId,
              value: item.chargeName ? '法人：' + item.chargeName : ''
            }
            return obj
          })
          // graph.nodes.unshift({
          //   name: '叶欢',
          //   id: 'acde00a1-c700-47e6-8d7e-2a2a7259d38b',
          //   value: '叶欢',
          //   category: 0,
          //   symbolSize: 120
          // })
          graph.links = res.result.relationshipListVos.map(item=> {
            return {
              source: item.parentId,
              target: item.childrenId,
              relation: item.relation
            }
          })
          option = {
            // title: { text: '关系图' },
            tooltip: {},
            // legend: [
            //   {
            //     data: graph.categories.map(function (a) {
            //       return a.name;
            //     })
            //   }
            // ],
            series: [
              {
                type: 'graph',
                layout: 'force',
                nodes: graph.nodes,
                // name: 'Les Miserables',
                // type: 'graph',
                // layout: 'none',
                // data: graph.nodes,
                links: graph.links,
                categories: graph.categories,
                edgeSymbolSize: [4, 10],
                roam: true,
                // itemStyle: {
                //   color: {
                //     type: "radial",
                //     x: 0.5,
                //     y: 0.5,
                //     r: 0.5,
                //     colorStops: [
                //       {
                //         offset: 0,
                //         color: "#3dd67a", // 0% 处的颜色
                //       },
                //       {
                //         offset: 0.7,
                //         color: "#3dd67a", // 0% 处的颜色
                //       },
                //       {
                //         offset: 1,
                //         color: "#95dcb2", // 100% 处的颜色
                //       },
                //     ],
                //     global: false, // 缺省为 false
                //   },
                // },
                // roam: true,
                label: {
                  show: true,
                  position: "bottom",
                  distance: 5,
                  fontSize: 14,
                  align: "center",
                },
                autoCurveness: 0.01, //多条边的时候，自动计算曲率
                edgeLabel: {//边的设置
                  show: true,
                  position: "middle",
                  fontSize: 12,
                  formatter: (params) => {
                    return params.data.relation.name;
                  },
                },
                edgeSymbol: ["circle", "arrow"], //边两边的类型
                force: {
                  repulsion: 500,
                  gravity: 0.01,
                  edgeLength: 200
                  // repulsion: 2500,
                  // edgeLength: [10, 50]
                },
                // labelLayout: {
                //   hideOverlap: true
                // },
                scaleLimit: {
                  min: 0.04,
                  max: 20
                },
                lineStyle: {
                  color: 'source',
                  curveness: 0.3
                }
              }
            ]
          };
          this.graph = graph
          this.option = option
          myChart.setOption(option);
        } else {
          Toast.fail('获取失败~')
        }
      }).finally(() => {
        myChart.hideLoading();
      })

    };
    // window.onload = function () {
    //   // 接下来的使用就跟之前一样，初始化图表，设置配置项
    //   var myChart = echarts.init(document.getElementById('relationCharts'));
    //   myChart.setOption({
    //     title: {
    //       text: 'ECharts 入门示例'
    //     },
    //     tooltip: {},
    //     xAxis: {
    //       data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
    //     },
    //     yAxis: {},
    //     series: [
    //       {
    //         name: '销量',
    //         type: 'bar',
    //         data: [5, 20, 36, 10, 10, 20]
    //       }
    //     ]
    //   });
    // }
  },
  methods: {
    onChange(e) {
      this.colorChecked = e
      console.log('myChart-', myChart);
      if (!myChart) {
        return
      }
      myChart.dispose();
      myChart = echarts.init(document.getElementById('relationCharts'), this.colorChecked ?'dark':'');
      myChart.setOption(option);
    }
  },
};
</script>

<style lang="less" scoped>
#relationCharts {
  width: 100vw;
  height: 100vh;
}
.switch {
  background-color: #100C2A;
}
.spancolor {
  color: #ffffff;
}
.switchF {
  background-color: rgb(116, 116, 116);
}
.d {
  padding: 10px;
  display: flex; 
  align-items: center;
  justify-content: space-between;
}
</style>