<template>
  <j-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    :fullscreen="true"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <j-form-container :disabled="formDisabled">
      <a-form-model
        ref="form"
        slot="detail"
        :model="model"
        :rules="validatorRules"
      >
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="name"
          label="任务名称（必填）"
        >
          <a-input placeholder="请输入任务名称" v-model="model.name" />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="projectId"
          label="项目"
        >
          <JSelectMultiProject
            :value="model.projectId"
            @select="select"
            :query-config="selectProjectQueryConfig"
          ></JSelectMultiProject>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="assignName"
          label="任务分配人（必填）"
        >
          <search-select
            :multi="false"
            :btnShow="true"
            :value="model.assignName"
            @selUsers="selUsers"
          ></search-select>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="type"
          label="时间类型"
        >
          <a-select
            v-model="model.hrTaskTimingAttribute.frequencyType"
            placeholder="选择时间类型"
          >
            <a-select-option :value="1">日</a-select-option>
            <a-select-option :value="2">月</a-select-option>
            <a-select-option :value="3">季度</a-select-option>
            <a-select-option :value="4">年</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="type"
          label="任务负责人类型"
        >
          <a-select
            v-model="model.hrTaskTimingAttribute.type"
            placeholder="选择负责人类型"
          >
            <a-select-option :value="1">用户</a-select-option>
            <a-select-option :value="2">角色</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="model.hrTaskTimingAttribute.type == 1"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="targetId"
          label="任务负责人员"
        >
          <search-select
            :multi="true"
            :btnShow="true"
            :value="model.hrTaskTimingAttribute.targetId"
            @selUsers="chargeName"
          ></search-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="model.hrTaskTimingAttribute.type == 2"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="targetId"
          label="任务负责角色"
        >
          <j-multi-select-tag
            :disabled="formDisabled"
            v-model="model.hrTaskTimingAttribute.targetId"
            :options="rolesOptions"
            placeholder="请选择角色"
          >
          </j-multi-select-tag>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="budgetAmount"
          label="预算奖金"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            placeholder="请输入预算奖金"
            v-model="model.budgetAmount"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="estimatedHour"
          label="分配人预估工时（必填）"
        >
          <a-input-number
            style="width: 100%"
            placeholder="请输入预估工时"
            v-model="model.estimatedHour"
          />
        </a-form-model-item>
        <!-- <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="reviewHour" label="负责人预估工时">
          <a-input-number style="width: 100%" placeholder="请输入预估工时" v-model="model.reviewHour" />
        </a-form-model-item> -->
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="auditedHour"
          label="审批工时"
        >
          <a-input-number
            style="width: 100%"
            placeholder="请输入审批工时"
            v-model="model.auditedHour"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="planStartTime"
          label="计划开始时间（必填）"
        >
          <a-date-picker
            style="width: 100%"
            showTime
            valueFormat="YYYY-MM-DD HH:mm:ss"
            v-model="model.planStartTime"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="planEndTime"
          label="计划结束时间（必填）"
        >
          <a-date-picker
            style="width: 100%"
            showTime
            valueFormat="YYYY-MM-DD HH:mm:ss"
            v-model="model.planEndTime"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="startTime"
          label="实际开始时间"
        >
          <a-date-picker
            style="width: 100%"
            showTime
            valueFormat="YYYY-MM-DD HH:mm:ss"
            v-model="model.startTime"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="endTime"
          label="实际结束时间"
        >
          <a-date-picker
            style="width: 100%"
            showTime
            valueFormat="YYYY-MM-DD HH:mm:ss"
            v-model="model.endTime"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="description"
          label="任务描述（必填）"
        >
          <a-textarea
            v-model="model.description"
            placeholder="请输入任务描述"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="attachments"
          label="附件"
        >
          <j-upload v-model="model.attachments"></j-upload>
        </a-form-model-item>
      </a-form-model>
    </j-form-container>
  </j-modal>
</template>

<script>
import { baseUrl } from "@/api/api.js";
import { GetAtion, HttpAction } from "@/utils/http";
import { queryall } from "@/api/api";
import SearchSelect from '@/components/SearchSelectUser/SearchSelect';
import JSelectMultiProject from '@/views/hrProjectManage/hrTask/modules/JSelectMultiProject';

export default {
  name: "HrProjectModal",
  components: {
    SearchSelect,
    JSelectMultiProject
  },
  data() {
    return {
      title: "操作",
      visible: false,
      rolesOptions: [],
      model: {
        hrTaskTimingAttribute: {},
      },
      // 选择用户查询条件配置
      selectUserQueryConfig: [{ key: "phone", label: "电话" }],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      // 选择项目查询条件配置
      selectProjectQueryConfig: [],
      confirmLoading: false,
      validatorRules: {
        name: [{ required: true, message: '请输入任务名称!' }],
        assignName: [{ required: true, message: '请选择任务分配人!' }],
        // chargeName: [{ required: true, message: '请选择任务负责人!' }],
        planStartTime: [{ required: true, message: '请选择计划开始时间!' }],
        planEndTime: [{ required: true, message: '请选择计划结束时间!' }],
        startTime: [{ required: true, message: '请选择实际开始时间!' }],
        estimatedHour: [{ required: true, message: '请输入预估工时!' }],
        auditedHour: [{ required: true, message: '请输入审批工时!' }],
        // budgetAmount: [{ required: true, message: '请输入预算奖金!' }],
        description: [{ required: true, message: '请输入任务描述!' }],
      },
      url: {
        add: baseUrl + "/hr-server/hrTask/add",
        edit: baseUrl + "/hr-server/hrTask/edit",
      },
      hrProjectMember: "",
      processName: "",
      procDefId: "",
      disableSubmit: false,
    };
  },
  created() {
    this.initRoleList();
  },
  watch: {},
  computed: {
    formDisabled() {
      return this.disableSubmit;
    },
  },
  methods: {
    selUsers(value) {
      if (value && value.length) {
        this.model.assignName = value[0].key;
      } else {
        this.model.assignName = "";
      }
    },
    select(value) {
      if (value.length) {
        this.model.projectId = value[0].id;
      } else {
        this.model.projectId = "";
      }
    },
    chargeName(obj) {
      if (obj) {
        this.model.hrTaskTimingAttribute.targetId = obj
          .map((item) => item.key)
          .join(",");
      } else {
        this.model.hrTaskTimingAttribute.targetId = "";
      }
    },
    //初始化角色字典
    initRoleList() {
      queryall().then((res) => {
        if (res.success) {
          this.rolesOptions = res.result.map((item, index, arr) => {
            let c = { label: item.roleName, value: item.id };
            return c;
          });
        }
      });
    },
    add(record) {
      //初始化默认值
      this.edit({ ...record, hrTaskTimingAttribute: {} });
    },
    edit(record) {
      console.log("record:------------------", record);
      this.model = Object.assign({}, record);
      this.model.executeType = 2;
      this.visible = true;
    },
    close() {
      this.$emit("close");
      this.visible = false;
      this.$refs.form.clearValidate();
    },
    handleOk(cb) {
      if (this.formDisabled) return;
      const that = this;
      // 触发表单验证
      this.$refs.form.validate((valid) => {
        if (valid) {
          that.confirmLoading = true;
          let httpurl = "";
          let method = "";
          if (!this.model.id) {
            httpurl += this.url.add;
            method = "post";
          } else {
            httpurl += this.url.edit;
            method = "put";
          }
          HttpAction(
            httpurl,
            {
              domain: this.model,
              processName: "",
              procDefId: "",
            },
            method
          )
            .then((res) => {
              if (res.success) {
                that.$emit("ok", this.model);
                typeof cb === "function" && cb(valid);
              } else {
                that.$message.warning(res.message);
              }
            })
            .finally(() => {
              that.confirmLoading = false;
              that.close();
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.close();
    },
  },
};
</script>

<style lang="less" scoped>
.select_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>