<template>
  <div :class="[pcOrh5==1?'bc':'bc2']">
    <div ref="map" class="chinaMap" >
    </div>
    <div class="listbox">
      <a-table class="ant-table-striped"
       size="small" 
       :dataSource="dataSource" 
       :columns="columns"  
       @change="handleTableChange"
        :pagination="{
         defaultPageSize:26,
         current:num
       }"
       />
    </div>
  </div>
</template>

<script>
import { 
  Toast,
} from "vant";
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import {  
  BarChart,
  LineChart
   } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {   
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent
   } from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
// import { LabelLayout } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import { getmapDataList } from "@/api/api"

var myChart;
// 注册必须的组件
echarts.use([
  TitleComponent,
   TooltipComponent,
    GridComponent, 
    BarChart,LineChart, 
    CanvasRenderer,
    VisualMapComponent
]);
import "../chinajs/china";
import { GetAtion } from "@/utils/http";
import { color } from 'echarts/core';

export default {
  name:'provinceEacharts',
  props:['fynum'],

  data() {
    return {
      dataSource:null,
      timer:null,
      pcOrh5:1,
      num:1,

         columns: [
          {
            title: '地区',
            dataIndex: 'name',
            key: 'name',
            align:"center",
             
          },
          {
            title: '分子公司数量',
            dataIndex: 'value',
            align:"center",
          },
          {
            title: '完成率',
            dataIndex: 'percentage',
            align:"center",
            //  customRender:function ({text,record,index}) {
            //   return text
            // }
          }
         
        ],
      option: {
          title: {
          text: "全国市场数据统计",
          subtext: "各省会分布",
          textStyle:{
           color:'#fff',
           fontSize:16
          },
          subtextStyle:{
           color:'#fff',
           fontSize:12
          }
        },
            tooltip: {
          show: false
        },
           tooltip: {
                  show: false
                },
                toolbox: {

        　　show: true,

        　　feature: {

        　　　　saveAsImage: {

        　　　　show:true,

        　　　　excludeComponents :['toolbox'],
               title:'下载',
        　　　　pixelRatio: 2,
               backgroundColor:'rgb(5, 18, 77)'

        　　　　}

        　　}

        },
        geo: {
          map: "china",
          roam: false,// 一定要关闭拖拽
          zoom: 1.23,
          center: [105, 36], // 调整地图位置
          label: {
            normal: {
              show: false, //关闭省份名展示
              fontSize: "10",
              color: "rgba(0,0,0,0.7)"
            },
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              areaColor: "#0d0059",
              borderColor: "#389dff",
              borderWidth: 1, //设置外层边框
              shadowBlur: 5,
              shadowOffsetY: 8,
              shadowOffsetX: 0,
              shadowColor: "#01012a"
            },
            emphasis: {
              areaColor: "#184cff",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 5,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        },
        series: [
          {
            type: "map",
            map: "china",
            roam: false,
            zoom: 1.23,
            center: [105, 36],
            // geoIndex: 1,
            // aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                areaColor: "#0d0059",
                borderColor: "#389dff",
                borderWidth: 0.5
              },
              emphasis: {
                areaColor: "#17008d",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },
          {
            name: "",
            type: "scatter",
            coordinateSystem: "geo",
            data: this.dealWithData(),
            //   symbolSize: function(val) {
            //     return val[2] / 10;
            //   },
            symbol: "circle",
            symbolSize: 8,
            hoverSymbolSize: 10,
            tooltip: {
              formatter(value) {
                return value.data.name + "<br/>" + "设备数：" + "22";
              },
              show: true
            },
            encode: {
              value: 2
            },
            label: {
              formatter: "{b}",
              position: "right",
              show: false
            },
            itemStyle: {
              color: "#0efacc"
            },
            emphasis: {
              label: {
                show: false
              }
            }
          },
          {
            name: "Top 5",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: [],
               symbolSize: function(val,params) {
                return params.data.size<8?8:(params.data.size>20?20:params.data.size)
              },

            tooltip: {
              show: false
            },
            encode: {
              value: 2
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
              color: "#0efacc",
              period: 9,
              scale: 5
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "right",
              show: true,
              color:'#fff',
              fontSize:16
            },
            itemStyle: {
              color: "#0efacc",
              shadowBlur: 2,
              shadowColor: "#333"
            },
            zlevel: 1
          }
        ]

      },
      myChart: undefined,
    };
  },
  components: {},
   watch:{
      num:{
      handler(){
        // getmapDataList()

       if(this.timer){
          clearTimeout(this.timer)
        }
         this.timer= setTimeout(()=>{
         this.num++
       if(Math.ceil(this.dataSource.length/26)<this.num){
         this.num=1
       }
      },8000)
      },
      immediate: true
      // let times=setTimeout()
   
    },
    fynum:{
  handler(){ 
   if(this.fynum==1){
      
        this.num=1
   }
      },
      immediate: true
    }
  },
   beforeDestroy(){
   if(this.timer){
          clearTimeout(this.timer)
        }
  },
  mounted() {
    this.getmapDataList()
this.num=1
   //页面首次加载获取屏幕宽度
   if(document.body.clientWidth<1200){
       this.pcOrh5=2
       this.option.title.subtextStyle.fontSize=10
       this.option.title.textStyle.fontSize=18
       this.option.series[2].label.fontSize=10

   }else{
       this.pcOrh5=1
       this.option.title.subtextStyle.fontSize=16
       this.option.title.textStyle.fontSize=24
       this.option.series[2].label.fontSize=16
   }
    // 动态监听屏幕尺寸变化从而修改font-size
    window.onresize = () => {
     console.log(document.documentElement.getBoundingClientRect().width,'屏幕尺寸变化了')
     if(document.documentElement.getBoundingClientRect().width<1200){
       this.pcOrh5=2
       this.option.title.subtextStyle=24

     }else{
       this.pcOrh5=1
       this.option.title.subtextStyle=16
       
     }
    }
  },
  methods: {

handleTableChange(e){
this.num=e.current
},

async getmapDataList(){
       try {
     let data= await getmapDataList({type:2,startTime:this.$route.query.startTime||null,endTime:this.$route.query.endTime||null})
     console.log(data,'123321')
     this.subtitle=data.result.subtitle
     this.title=data.result.title
    
     this.dataSource=data.result.data
 data.result.data.forEach((e,i)=>{
     this.dataSource[i].percentage=e.value*10>=100?'100%':(e.value*10)+'%'
     })

       var myChart =echarts.init(this.$refs.map);
       myChart.setOption(this.option);
       this.myChart = myChart;
       //从后端请求数据
          this.option.series[2].data = this.dealWithData();
          this.option.title.subtext= this.subtitle
          this.option.title.text= this.title

      //进行地图赋值
        this.myChart.setOption(this.option);
         console.log(this.option,123)
        //  this.option.visualMap.pieces[0].min=this.dataSource[0].value
       } catch (error) {
         
       }
     },


     dealWithData() {
     //各省会城市坐标
      var geoCoordMap = 
      {
        北京: [116.46, 39.92],
        上海: [121.48, 31.22],
        天津: [117.2, 39.13],
        重庆: [106.54, 29.59],
        哈尔滨: [126.63, 45.75],
        长春: [125.35, 43.88],
        沈阳: [123.38, 41.8],
        呼和浩特: [111.65, 40.82],
        石家庄: [114.48, 38.03],
        乌鲁木齐: [87.68, 43.77],
        兰州: [103.73, 36.03],
        西宁: [101.74, 36.56],
        西安: [108.95, 34.27],
        银川: [106.27, 38.47],
        郑州: [113.65, 34.76],
        济南: [117, 36.65],
        太原: [112.53, 37.87],
        合肥: [117.27, 31.86],
        长沙: [113, 28.21],
        武汉: [114.31, 30.52],
        南京: [118.78, 32.04],
        成都: [104.06, 30.67],
        贵阳: [106.71, 26.57],
        昆明: [102.73, 25.04],
        南宁: [108.33, 22.84],
        拉萨: [91.11, 29.97],
        杭州: [120.19, 30.26],
        南昌: [115.89, 28.68],
        广州: [113.23, 23.16],
        福州: [119.3, 26.08],
        海口: [110.35, 20.02],
        台北: [121.6, 25],
  }
      var data = [];
      for (var key in geoCoordMap) {
       if(this.dataSource){
       this.dataSource.forEach(e=>{
        if(e.name==key){
        data.push({ name: e.name, value: geoCoordMap[key],size:e.value });

        }
      })
      }
      }
      return data;
    },

   
  },
};
</script>
<style lang="less" scoped>
.bc {
  padding: 10px;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex; 
  align-items: center;
  justify-content: space-between;
  // background-color:  #fafafa;
  background: url("../../../assets/eachartsbg.png") no-repeat center center fixed;
  background-size:cover;
  //  /* 此处加上-ms前缀，让IE识别 */ 
  // -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../../assets/eachartsbg.png',sizingMethod='scale')";
  // filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../../../assets/eachartsbg.png',sizingMethod='scale');
  // /* \9 是 IE的CSS hack,IE9以下识别，不包括IE 9 */
  // background: none\9;
;
  .chinaMap{
  padding: 10px;
  width: 100vw;
  height: 100vh;
 
  }
   .listbox{
    z-index: 999999999;
    margin-top: 10px;
       margin-right: 50px;
        width: 500px;

    height: 100%;
   /deep/ .ant-table-body{
     margin: 0;
     border:0;

   }
    /deep/ .ant-table-small{
    border: 1px solid #78808b;
    // border-bottom:1px solid #78808b ;
    border-right:0 ;
  }
  /deep/ .ant-table-striped td{
     color: #fff;
     font-weight: 600;
     padding: 1px !important;
     height: .3125rem !important;
     box-sizing: border-box;
     font-size: 13px;
     border-right:1px solid #78808b;
     border-bottom:1px solid #78808b;
  }
   
 /deep/ .ant-table-thead tr{
     .ant-table-align-center{
       height: .4688rem !important;
       box-sizing: border-box;
       background-color: #003a8c !important;
       border-bottom:1px solid #78808b;
       border-right:1px solid #78808b;
       color: #fff;
     }
  }
  }


}
.bc2{
   padding: .1563rem;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  display: flex; 
  align-items: center;
  // justify-content: center;
  flex-flow: column;
  // background-color:  #fafafa;
  background: url("../../../assets/eachartsbg.png") no-repeat center center fixed;
  background-size:cover;
  background-color: rgb(5, 14, 71);
 
;
  .chinaMap{
  // padding: .1563rem;
  width: 100%;
  height: 100%;
 
  }
   .listbox{
    z-index: 999999999;
    margin-top: .4688rem;
    margin-right: .7813rem;
    width: 60%;
    height: 100%;
   /deep/ .ant-table-body{
     margin: 0;
     border:0;

   }
  /deep/ .ant-table-small{
    border: 1px solid #78808b;
    // border-bottom:1px solid #78808b ;
    border-right:0 ;
  }
  /deep/ .ant-table-striped td{
     color: #fff;
     font-weight: 600;
     padding: 1px !important;
     height: .3125rem !important;
     box-sizing: border-box;
     font-size: .2031rem;
     border-right:1px solid #78808b;
     border-bottom:1px solid #78808b;
  }
   
 /deep/ .ant-table-thead tr{
     .ant-table-align-center{
       height: .4688rem !important;
       box-sizing: border-box;
       background-color: #003a8c !important;
       border-bottom:1px solid #78808b;
       border-right:1px solid #78808b;
       color: #fff;
     }
  }
  }

}

</style>

