<template>
  <div class="editable-cell" style="width: 200px;">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <div class="flex">
        <search-select
          style="flex: 1;"
          :multi="multiple"
          :btnShow="true"
          :value="value"
          @selUsers="chargeName"></search-select>
          <a-icon type="check" class="editable-cell-icon-check" @click="check" />
      </div>
    </div>
    <div v-else class="editable-cell-text-wrapper" @click="edit">
      {{ value || ' ' }}
      <!-- <a-icon type="edit" class="editable-cell-icon" @click="edit" /> -->
    </div>
  </div>
</template>

<script>
import SearchSelect from '@/components/SearchSelectUser/SearchSelect';
export default {
  components: {
    SearchSelect
  },
  props: {
    text: String,
    multiple: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    chargeName(value) {
      if (value && value.length) {
        this.value = value[0].key
      } else {
        this.value = ''
      }
    },
    handleChange(e) {
      const value = e;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit('change', this.value);
    },
    edit() {
      this.editable = true;
    },
  },
}
</script>

<style>
.flex {
  display: flex;
  align-items: center;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>