import { 
    GetAtion,//get
    HttpAction,//post/put
    deleteActionApi,//delete
    PostJson,//post-json
    PostForm,//post-form
} from '@/utils/http'

const url = process.env.VUE_APP_API_ACI_URL;
const baseUrl = url + "/activiti-server/activiti";

//获取角色权限列表
const getUserRoleSet = (params) => GetAtion(url + "/sfsj-server/sys/api/getUserRoleSet",params);

//自定义表单list
const getCgFormList = (params) => GetAtion(baseUrl + "/sfsj-server/cgform/list",params);
//获取主表所有关联附表的表单名
const getHeadqueryById = (params) => GetAtion(baseUrl + "/sfsj-server/online/cgform/head/queryById", params);
//获取附表表单数据字段列表
const getFieldList = (params) => GetAtion(baseUrl + "/sfsj-server/online/cgform/field/listByHeadCode", params);
//获取表单字段
const getListByHeadId = (params) => GetAtion(baseUrl + "/online/cgform/field/listByHeadId",params);
// 获取自定义表单
const getQueryByHeadId = (params) => GetAtion(baseUrl + "/sfsj-server/cgform/queryByHeadId/",params);


//获取流程信息
const getActStartParam = (params) => GetAtion(baseUrl + `/definition/getActStartParam/${params}`,);
//获取流程信息
const getActStartProcess = (params) => GetAtion(baseUrl + `/definition/process/${params}`,);
//创建模型
const modelCreate = (params) => PostForm(baseUrl + "/modeler/create",params);
//获取流程定义列表--list
const getModDefList = (params) => GetAtion(baseUrl + "/definition/list",params);
//部署流程--发布
const addDeploy = (params) => GetAtion(baseUrl + "/modeler/deploy/"+ params);
//挂起
const suspendMod = (params) => PostForm(baseUrl + "/process/suspendOrActiveApply",params);
//激活
const activeMod = (params) => PostForm(baseUrl + "/process/suspendOrActiveApply",params);
// 删除模型--删除
const deleteMod = (params) => deleteActionApi(baseUrl + "/definition/remove/"+ params);
//编辑获取xml--设计
const getEditModel =  (params) => GetAtion(baseUrl + "/modeler/" + params);
// 保存草稿
const saveModel = (params) => PostForm(baseUrl + "/model/save", params)
// 保存部署
const saveModelBS = (params) => PostForm(baseUrl + "/process/addDeploymentByString", params)


//模型分组add
const fzAdd = (params) => PostJson(baseUrl + "/modeler/category/save", params)
// 模型分组列表
const getFzList = (params) => GetAtion(baseUrl + "/modeler/category/all", params)
// 模型分组删除
// const delCategory = (params) => GetAtion(baseUrl + "/modeler/category/all", params)

//获取我的待办列表
const getTodoList = (params) => PostJson(baseUrl + "/process/taskList",params);
// 待办列表--委托办理
const trustHandler = (params) => PostForm(baseUrl + "/process/delegate",params);


//获取我的已办列表
const getDoneList = (params) => PostJson(baseUrl + "/process/taskDoneList",params);


//我发起的/我的申请list
const getApplyList = (params) => PostJson(baseUrl + "/process/initiate/list", params);


//发起流程
const getProcessList = (params) => PostForm(baseUrl + "/modeler/category/list", params);



//获取流程历史-流程图线条高亮
const getHightLine = (params) => GetAtion(baseUrl + "/definition/get/highLine",params);
// 获取模型xml
const getModel = (params) => GetAtion(baseUrl + "/definition/getDefinitionXML/" + params);
//审批历史jilu
const historyList = (params) => PostJson(baseUrl + "/process/listHistory",params);
//真实的处理流程===用来回显审批数据的
const clHistLc = (params) => PostJson(baseUrl + "/process/sub/listHistory",params);

// 进度查看
const getLcInfo = (params) => PostForm(baseUrl + "/process/read-resource", params)
// 办理--处理
const completeHandler = (params) => PostJson(baseUrl + "/process/complete",params);
//获取表单key
const getFormKey = (params) => GetAtion(baseUrl + "/definition/get/form", params);
//获取表单JSON
const getFormJson = (params) => GetAtion(baseUrl + "/sfsj-server/cgform/queryByFormCode", params);

//表单保存
const formSave = (params) => PostJson(`${baseUrl}definition/save/${params.code}?${params.query}`, params.data);
const custFormSave = (params) => PostJson(baseUrl + params.url, params.data);

// 获取表单数据
const getFormData = (params) => GetAtion(baseUrl + `/sfsj-server/online/cgform/api/form/${params.cgformHeadId}/${params.businessKey}`);
const getCustFormData = (params) => GetAtion(baseUrl + params.url, params.query);
const setFormData = (params) => HttpAction(baseUrl + `/sfsj-server/online/cgform/api/form/${params.query}`, params.obj, params.method);

// 启动流程
const qdApply = (params) => PostJson(baseUrl + "/definition/apply",params);


//流程监控数据
const processMonitorList = (params) => PostForm(baseUrl + "/process/admin/list", params);

//根据activiti中间表获取流程数据
const getProcessByActi = (params) => GetAtion(baseUrl + `/definition/find/${params}`);

//获取表单操作按钮
const getHandlerBtn = (params) => GetAtion(baseUrl + `/definition/variables`,params);

//获取流程数据通过key
const getProcessByKey = (params) => GetAtion(baseUrl + `/definition/process/${params}`);
//测试请假
// const addApply = (params) => PostJson(baseUrl + "//jeecg-activiti/leave/leave",params);
// const qjApply = (params) => PostForm(baseUrl + "//jeecg-activiti/leave/leave/submitApply/"+ params);
// const qjList = (params) => GetAtion(baseUrl + "//jeecg-activiti/leave/leave/list",params);
/******标准规范表****** */
const stateChange = (params,method) => HttpAction(baseUrl + "/testcenter-server/hr.train/jcStandardSpecification/edit",params,method);
const noveltySearchHandler = (params) => PostJson(baseUrl + "/testcenter-server/train/jcStandardSearch/init",params);
//获取查新记录的id
const getSearchId = (params) => GetAtion(baseUrl + "/testcenter-server/train/jcStandardSearch/manual/init",params);
/****人员培训计划--培新记录--外聘人员查询*** */
const getHrExpertAssociation = (params) => GetAtion(baseUrl + "/testcenter-server/train/hrTrainPlan/queryHrExpertAssociationByMainId",params);

//中间跳转页
const TurningThePage = (params) => GetAtion(url +"/sfsj-server/sys/message/urlSwitch/queryById",params);

export {
  modelCreate,
  getModDefList,
  addDeploy,
  suspendMod,
  activeMod,
  getTodoList,
  getDoneList,
  getHightLine,
  deleteMod,
  completeHandler,
  // HttpAction,
  trustHandler,
  getUserRoleSet,
  historyList,
  clHistLc,
  getLcInfo,
  getModel,
  saveModel,
  saveModelBS,
  getEditModel,
  getApplyList,
  getProcessList,
  setFormData,
  fzAdd,
  getFzList,
  // delCategory,
  getActStartProcess,
  getListByHeadId,
  getFormKey,
  getFormJson,
  getActStartParam,
  //
  getCgFormList,
  getHeadqueryById,
  getFieldList,
  formSave,
  custFormSave,
  getFormData,
  getCustFormData,
  qdApply,
  getQueryByHeadId,
  //
  processMonitorList,
  getProcessByActi,

  getHandlerBtn,

  getProcessByKey,

  // addApply,
  // qjApply,
  // qjList
  //标准规范表的状态改变
  stateChange,
  noveltySearchHandler,
  getSearchId,
  //人员培训---外聘人员
  getHrExpertAssociation,
  
  TurningThePage
}