<template>
  <j-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭">
    
    <a-spin :spinning="confirmLoading">
      <a-form-model ref="form" :model="model" :rules="validatorRules">
        <a-form-model-item 
            v-if="type === 'start'"
            :labelCol="labelCol" 
            :wrapperCol="wrapperCol" 
            prop="startTime" 
            label="实际开始时间">
          <a-date-picker showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.startTime" />
        </a-form-model-item>
        <a-form-model-item 
            v-if="type === 'end'"
            :labelCol="labelCol" 
            :wrapperCol="wrapperCol" 
            prop="endTime" 
            label="实际结束时间">
          <a-date-picker showTime valueFormat='YYYY-MM-DD HH:mm:ss' v-model="model.endTime" />
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </j-modal>
</template>

<script>
  import { baseUrl } from "@/api/api.js"
  import { GetAtion, HttpAction } from "@/utils/http"
  import { getActStartProcess } from '@/api/activitiApi';
  export default {
    name: "HrProjectModal",
    components: {},
    data () {
      return {
        title:"操作",
        visible: false,
        model: {},
        // 选择用户查询条件配置
        selectUserQueryConfig: [
          {key: 'phone', label: '电话'},
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        validatorRules:{
          startTime: [{ required: true, message: '请选择实际开始时间!' }],
        },
        url: {
          add: baseUrl + "/hr-server/hrProject/add",
          edit: baseUrl + "/hr-server/hrProject/edit",
        },
        hrProjectMember: '',
        type: ''
      }
    },
    created () {
    },
    watch: {
    },
    computed: {
    },
    methods: {
      add () {
        //初始化默认值
        this.edit({ hrProjectMemberList: [] });
      },
      edit (record, type) {
        this.type = type
        this.model = Object.assign({}, record);
        if (this.model.hrProjectMemberList && this.model.hrProjectMemberList.length) {
          this.hrProjectMember = this.model.hrProjectMemberList.map(item => item.username).join(',')
        }
        this.visible = true;
      },
      close () {
        this.$emit('close');
        this.visible = false;
        this.$refs.form.clearValidate();
      },
      handleOk () {
        const that = this;
        // 触发表单验证
         this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
              method = 'put';
            }
            getActStartProcess('hr_project').then(result=>{
              HttpAction(httpurl, {
                domain: this.model,
                processName: result.result.name,
                procDefId: result.result.id
              },method).then((res)=>{
                if(res.success){
                  that.$message.success(res.message);
                  that.$emit('ok');
                }else{
                  that.$message.warning(res.message);
                }
              }).finally(() => {
                that.confirmLoading = false;
                that.close();
              })
            })
          }else{
             return false;
          }
        })
      },
      handleCancel () {
        this.close()
      },
    }
  }
</script>

<style lang="less" scoped>
.select_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>