<template>
  <!-- 定义在这里的参数都是不可在外部覆盖的，防止出现问题 -->
  <j-select-biz-component
    :value="value"
    :ellipsisLength="25"
    :listUrl="url.list"
    :columns="columns"
    v-on="$listeners"
    v-bind="attrs"
  />
</template>

<script>
  import { baseUrl } from "@/api/api.js"
  import { GetAtion, HttpAction } from "@/utils/http"
  import JSelectBizComponent from '@/components/JSelectBizComponent/index.vue'

  export default {
    name: 'JSelectMultiEquipment',
    components: {JSelectBizComponent},
    props: {
      value: null, // any type
      queryConfig: {
        type: Array,
        default: () => []
      },
    },
    
    data() {
      return {
        projectType: {},
        url: { 
            list: baseUrl + '/hr-server/hrProject/list',
            getDictItem: baseUrl + "/sfsj-server/sys/dict/getDictItem", 
        },
        columns: [
            { title: '项目名称', dataIndex: 'name', align: 'center', width: '30%', widthRight: '70%' },
            { title: '负责人', dataIndex: 'chargeName', align: 'center', width: '35%' },
        ],
        // 定义在这里的参数都是可以在外部传递覆盖的，可以更灵活的定制化使用的组件
        default: {
          name: '项目信息',
          width: 1200,
          displayKey: 'name',
          returnKeys: ['id', 'name'],
          queryParamText: '项目名称',
          returnId: true,
          multiple: false, // false单选
          jSelectMultiType: 'project',
        },
        // 多条件查询配置
        queryConfigDefault: [
        //   {
        //     key: 'sex',
        //     label: '性别',
        //     // 如果包含 dictCode，那么就会显示成下拉框
        //     dictCode: 'sex',
        //   },
        //   {
        //     key: 'departIds_dictText',
        //     label: '公司',
        //     // 如果包含 dictCode，那么就会显示成下拉框
        //     dictCode: 'departIds_dictText',
        //   },
        //   {
        //     key: 'birthday',
        //     label: '生日',
        //     placeholder: '请选择出生日期',
        //     // 如果想要使用局部注册的组件，就必须要使用箭头函数
        //     customRender: ({key, queryParam, options}) => {
        //       return <j-date {...options} vModel={queryParam[key]} style="width:180px;"/>
        //     },
        //   },
        ],
      }
    },
    created() {
        this.getDictItem('projectType')
    },
    computed: {
      attrs() {
        return Object.assign(this.default, this.$attrs, {
          queryConfig: this.queryConfigDefault.concat(this.queryConfig)
        })
      }
    },
    methods: {
        getDictItem(code) {
            //根据字典Code, 初始化字典数组
            GetAtion(this.url.getDictItem, { code }).then((res) => {
                if (res.success) {
                    let arr = code.split(';')
                    arr.forEach(item=>{
                        res.result[item].forEach(element=>{
                            this.$set(this[item], element.value, element.text)
                        })
                    })
                }
            })
        }
    },
  }
</script>

<style lang="less" scoped></style>