<template>
  <div class="search_select">
    <div class="btn_search">
      <a-select
        :mode="reMode"
        label-in-value
        :value="selValue"
        :disabled="disabled"
        placeholder="请输入用户账号"
        show-search
        style="width: 100%"
        :not-found-content="fetching ? undefined : null"
        allowClear
        @search="fetchUser"
        @change="handleChange"
      >
        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
        <a-select-option v-for="d in optinData" :key="d.key">
          {{ d.label }}
        </a-select-option>
      </a-select>
      <a-button v-if="btnShow" :disabled="disabled" @click="openModal"
        >···</a-button
      >
    </div>
    <!-- 插槽弹窗 -->
    <slot-modal
      ref="useModal"
      :multi="multi"
      title="选择用户"
      :selUse="selValue"
      @selUser="selUser"
    >
    </slot-modal>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import SlotModal from "./components/SlotModal";
import { getUserLists } from "@/api/api";
export default {
  // name:'远程搜索空间',
  components: {
    SlotModal,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    multi: {
      type: Boolean,
      default: true,
    },
    btnShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    this.fetchUser = debounce(this.fetchUser, 800);
    return {
      optinData: [],
      selValue: [],
      fetching: false,
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          if (this.multi) {
            let array = val.split(",");
            this.selValue = array.map((element) => {
              return {
                key: element,
                label: element,
              };
            });
          } else {
            this.selValue = [{ key: val, label: val }];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    reMode() {
      return this.multi ? "multiple" : "default";
    },
  },
  methods: {
    fetchUser(value) {
      this.optinData = [];
      this.fetching = true;
      getUserLists({
        username: `*${value}*`,
      })
        .then((res) => {
          const optinData = res.result.records.map((user) => ({
            key: user.username,
            label: user.username,
          }));
          this.optinData = optinData;
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    handleChange(value, option) {
      if (!value) {
        this.selValue = [];
      } else {
        if (!this.multi) {
          this.selValue = [value];
        } else {
          this.selValue = value;
        }
      }
      this.$emit("selUsers", this.selValue);
    },
    openModal() {
      this.$refs.useModal.show();
    },
    //确认选中的用户
    selUser(showArr) {
      this.selValue = showArr;
      this.$emit("selUsers", showArr);
    },
  },
};
</script>

<style lang="less" scoped>
.btn_search {
  display: flex;
}
</style>